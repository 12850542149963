import React from "react";
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

export const ProtectedRoute: React.FC = () => {
  const { currentUser, loading } = useAuth();

  if (loading) {
    // Wyświetlanie informacji o ładowaniu
    return <div><span className="loader"></span></div>; // Możesz dodać tu spinner lub inną grafikę ładowania
  }

  if (!currentUser) {
    // Przekierowanie do strony logowania, jeśli użytkownik nie jest zalogowany
    return <Navigate to="/login" replace />;
  }

  // Renderowanie dziecka (Outlet) jeśli użytkownik jest zalogowany
  return <Outlet />;
};
