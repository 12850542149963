import React from "react";
import "./AccountPage.scss";
import { UserInfo } from '../../components/UserInfo';
import { useUserData } from '../../hooks/useUserData';

export const AccountPage: React.FC = () => {
  const [userInfo, fetchUserData] = useUserData();

  return (
    <>
      {userInfo ? (
        <UserInfo userInfo={userInfo} onUpdate={fetchUserData} />
      ) : (
        <p><span className="loader"></span></p>
      )}
    </>
  );
};
