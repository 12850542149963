// src\components\EstimateProducts\Header.tsx

import React from 'react';
import { Tooltip } from "../Tooltip";

interface HeaderProps {
  title: string;
  iconClass: string;
  category: string;
  total: number;
}

const Header: React.FC<HeaderProps> = ({ title, iconClass, category, total }) => {

  return (
    <div className="columns">
      <div className="column">
        <h2 className="title is-4">
          <span className="icon">
            <i className={iconClass}></i>
          </span>
          {' '}
          {title}
        </h2>
        {category === 'fronty' && total > 0 && (
          <div className="content">
            <h3 className="subtitle is-6 pl-2">
              Powierzchnia korpusów:
              {' '}
              <span className="has-text-danger">
                {total.toFixed(2)} m2
              </span>
              {' '}
              <Tooltip
                content="Powierzchnia została obliczona na podstawie produktów z zakładki KORPUSY (z włączoną opcją automatycznego obliczania powierzchni)."
                iconClassName="fa-regular fa-circle-question"
              />
            </h3>

          </div>
        )}
      </div>

    </div>
  );
}

export default Header;
