import React from 'react';
import { CommonItemsProps } from '../../types/commonTypes';
import DisplaySingleProduct from './DisplaySingleProduct';

interface ProductListProps {
  items: CommonItemsProps[];
  estimateId: number;
  onAddProduct: (productId: string) => void;
  onRemoveProduct: (productId: string) => void;
  onProductUpdate: () => void;
  isLocked: boolean;
  category: string; // Dodajemy nowy prop category
}

const ProductList: React.FC<ProductListProps> = ({ 
  items, 
  estimateId, 
  onRemoveProduct, 
  onProductUpdate, 
  isLocked,
  category // Dodajemy nowy prop category
}) => {
  return (
    <div className="columns is-multiline">
      {items.map((item) => (
        <div key={item.id} className="column is-3-fullhd is-4-widescreen is-4-desktop is-6-tablet is-12-mobile">
          <DisplaySingleProduct
            key={`${item.id}-${estimateId}`}
            {...item}
            estimateId={estimateId}
            onRemoveProduct={() => onRemoveProduct(item.id)}
            onProductUpdate={onProductUpdate}
            isArchived={item.isArchived || false}
            isActive={item.isActive}
            customSellingPrice={item.customSellingPrice}
            customPurchasePrice={item.customPurchasePrice}
            isLocked={isLocked}
            category={category} // Przekazujemy prop category
          />
        </div>
      ))}
    </div>
  );
};

export default ProductList;
