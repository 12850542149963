import React from 'react';
import './UserLogo.scss';
import { Link } from "react-router-dom";
import { useUserData } from '../../hooks/useUserData';
import { getConditionalUrl } from '../../utils/cdnHelper';

export const UserLogo: React.FC = () => {
  const [userInfo] = useUserData();

  // Użyj getConditionalUrl do podmiany adresu logo
  const logoUrl = userInfo?.company_logo ? getConditionalUrl(userInfo.company_logo) : '';

  return (
    <div className="logo-container">
      <Link to="/" className="link">
        <img src={logoUrl} alt="Logo" className="logo-image" />
      </Link>
    </div>
  );
};
