import { useState, ChangeEvent, useCallback } from 'react';
import axios from 'axios';
import { UserData } from '../types/UserData';
import config from '../config';
import { toast } from 'react-toastify';
import { Dispatch, SetStateAction } from 'react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useEditMode } from '../context/EditModeContext'; // Dodaj ten import

interface ManageUserDataReturn {
  formData: UserData;
  isLoading: boolean;
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  handleInputChange: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  handleSelectChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  saveUserData: () => Promise<void>;
  uploadLogo: (file: File) => Promise<void>;
  handleDirectUpdate: (field: string, value: string | boolean | null) => void;
  updateTooltipSetting: (enabled: boolean) => Promise<void>;
}

const useManageUserData = (initialData: UserData): ManageUserDataReturn => {
  const [formData, setFormData] = useState<UserData>(initialData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const apiUrl = config.API_URL;
  const { setTooltipsEnabled } = useEditMode(); // Użyj kontekstu EditMode

  const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = event.target;
    const inputElement = event.target as HTMLInputElement;
    
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? inputElement.checked : value
    }));
  }, []);

  const handleSelectChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  }, []);

  const handleDirectUpdate = useCallback((field: string, value: string | boolean | null) => {
    setFormData(prevData => ({
      ...prevData,
      [field]: value
    }));
  }, []);

  const updateTooltipSetting = useCallback(async (enabled: boolean) => {
    setIsLoading(true);
    try {
      await setTooltipsEnabled(enabled); // Aktualizuj globalny stan tooltipów
      setFormData(prevData => ({
        ...prevData,
        tooltips_enabled: enabled
      }));
    } catch (error) {
      console.error("Error updating tooltip settings", error);
      toast.error('Nie udało się zaktualizować ustawień tooltipów.');
    } finally {
      setIsLoading(false);
    }
  }, [setTooltipsEnabled]);

  const saveUserData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.put(`${apiUrl}/account/users/${formData.firebase_uid}`, formData);
      console.log('Data updated successfully:', response.data);
      setEditMode(false);
      toast.success('Dane zostały zaktualizowane pomyślnie!');
      // Aktualizuj globalny stan tooltipów po zapisaniu danych
      await setTooltipsEnabled(formData.tooltips_enabled);
    } catch (error) {
      console.error("Error updating user data", error);
      toast.error('Nie udało się zaktualizować danych.');
    } finally {
      setIsLoading(false);
    }
  };

  const uploadLogo = async (file: File): Promise<void> => {
    const storage = getStorage();
    const storageRef = ref(storage, `users/${formData.firebase_uid}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed',
      () => {
        setIsLoading(true);
      },
      (error) => {
        console.error("Error uploading file: ", error);
        toast.error('Nie udało się załadować logo.');
        setIsLoading(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFormData(prevData => ({ ...prevData, company_logo: downloadURL }));
          setIsLoading(false);
          toast.success("Logo zostało załadowane pomyślnie! Kliknij 'Zapisz', aby potwierdzić.");
        });
      }
    );
  };


  return {
    formData,
    isLoading,
    editMode,
    setEditMode,
    handleInputChange,
    handleSelectChange,
    saveUserData,
    uploadLogo,
    handleDirectUpdate,
    updateTooltipSetting
  };
}

export default useManageUserData;
