// src/components/Help/Help.tsx
import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./Help.scss";
import { topics } from "./HelpTopics";

export const Help: React.FC = () => {
  return (
    <div className="content">
      <Helmet>
        <title>Podręcznik - jak używać aplikacji | GoEstima</title>
        <meta name="description" content="Podręcznik użytkownika i FAQ. Prosty i czytelny przewodnik po funkcjach aplikacji. | kalkulator mebli kuchennych i nie tylko | GoEstima" />
        <link rel="canonical" href="https://goestima.pl/manual" />
      </Helmet>

      <section className="block mb-6 has-text-centered">
        <p className="title">
          <span className="icon pr-5 has-text-info">
            <i className="fa-solid fa-circle-question"></i>
          </span>
          <span>
            Pomoc
          </span>
        </p>
        <p className="subtitle">Podręcznik użytkownika i FAQ</p>
      </section>

      <section className="columns is-multiline mb-6">
        {topics.map(topic => (
          <div key={topic.id} className="column is-size-5 box is-one-quarter p-4 m-4">
            <span className="icon has-text-info">
              <i className="fa-regular fa-file-lines"></i>
            </span>
            {' '}
            <Link to={`#${topic.id}`}>
              {topic.title}
            </Link>
          </div>
        ))}
      </section>

      {topics.map(topic => {
        const TopicComponent = topic.component;
        return (
          <section key={topic.id} className="box p-6 mb-6">
            <h2 className="title" id={topic.id}>
              {topic.title}
            </h2>
            <TopicComponent />
          </section>
        );
      })}
    </div>
  );
};
