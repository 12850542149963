import React from "react";
import { Link } from "react-router-dom";
import "./Articles.scss";
import { Helmet } from "react-helmet";
import { articleList } from "./articleList";

export const Articles: React.FC = () => {
  return (
    <div className="articles">
      <Helmet>
        <title>Artykuły - meble na wymiar | GoEstima</title>
        <meta name="description" content="Artykuły o projektowaniu, wycenianiu, sprzedawaniu i produkowaniu mebli na wymiar" />
        <link rel="canonical" href="https://goestima.pl/articles" />
      </Helmet>

      <section className="hero is-primary">
        <div className="hero-body">
          <div className="content">
            <h1 className="title">Artykuły o meblarstwie</h1>
            <h2 className="subtitle">
              Projektowanie, wycenianie, sprzedaż i produkcja
            </h2>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="content">
          <div className="content">
            <p className="is-size-5">
              Witaj w naszej bazie wiedzy! Tutaj znajdziesz przydatne artykuły dotyczące branży mebli na wymiar.
              Niezależnie od tego, czy jesteś profesjonalistą szukającym nowych inspiracji, czy klientem chcącym
              dowiedzieć się więcej o procesie tworzenia mebli na zamówienie, mamy coś dla Ciebie. Nasze artykuły
              będą obejmowały szeroki zakres tematów, od najnowszych trendów w projektowaniu, przez efektywne metody wyceny,
              aż po zaawansowane techniki produkcji.
            </p>
          </div>
        </div>
      </section>

      <section className="section pt-2">
        <div className="columns is-multiline">
          {articleList.map((article) => (
            <div key={article.slug} className="column is-one-third">
              <div className="card">
                <div className="card-image">
                  <figure className="image is-4by3 ">
                    <img src={article.imageUrl} alt={article.title} />
                  </figure>
                </div>
                <div className="card-content">
                  <div className="media">
                    <div className="media-content">
                      <p className="title is-4">{article.title}</p>
                    </div>
                  </div>
                  <div className="content">
                    {article.description}
                    <br />
                    <Link to={`/articles/${article.slug}`} className="button is-primary is-outlined is-fullwidth mt-4">
                      Czytaj więcej
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};
