// src/components/Help/HelpTopics/FunkcjeIZasadaDzialania.tsx
import React from 'react';

export const FunkcjeIZasadaDzialania: React.FC = () => (
  <>
    <p>GoEstima to zaawansowany kalkulator mebli kuchennych, który pomaga w planowaniu i wycenie projektów kuchennych. Oto główne funkcje:</p>
    <ul>
      <li>Wybór elementów kuchennych z bazy danych</li>
      <li>Automatyczne obliczanie kosztów materiałów</li>
      <li>Generowanie raportów i zestawień</li>
    </ul>
    <p>
      Aby rozpocząć, wybierz ikonę
      <span className="icon px-5 is-size-5">
        <i className="fa-solid fa-folder-plus"></i>
      </span>
      u góry ekranu, lub na stronie głównej naciśnij na przycisk
      {' '}
      <button className="button mx-3 is-small">
        <span className="icon is-size-5">
          <i className="fa-solid fa-folder-plus"></i>
        </span>
        <span>
          Nowa wycena
        </span>
      </button>
    </p>
  </>
);
