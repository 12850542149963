import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { FirebaseError } from "firebase/app";
import axios from 'axios';
import { errorMessages } from '../../services/firebaseErrors';
import config from '../../config';

export const LoginForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const auth = getAuth();
  const apiUrl = config.API_URL;

  const getErrorMessage = (errorCode: string): string => {
    return errorMessages[errorCode] || "Nieznany błąd, spróbuj ponownie.";
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    
    try {
      console.log('Próba logowania użytkownika:', email);
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log("Zalogowano użytkownika:", userCredential.user.uid);

      // Sprawdź i ewentualnie dodaj użytkownika do bazy danych MySQL
      try {
        console.log("Wysyłanie żądania check-and-create");
        const response = await axios.post(`${apiUrl}/account/check-and-create`, {
          email: userCredential.user.email,
          firebase_uid: userCredential.user.uid,
          first_name: "",
          last_name: ""
        });
        console.log('User check/create response:', response.data);
      } catch (dbError) {
        console.error('Błąd przy sprawdzaniu/dodawaniu użytkownika do bazy danych:', dbError);
        if (axios.isAxiosError(dbError)) {
          console.error('Szczegóły błędu:', dbError.response?.data);
        }
        setError("Wystąpił problem z synchronizacją konta. Spróbuj ponownie później.");
        return;
      }

      // Pobierz token Firebase
      const token = await userCredential.user.getIdToken();

      // Ustaw token w ciasteczku za pomocą zapytania do serwera
      try {
        await axios.post(`${apiUrl}/account/set-token`, { token });
        console.log('Token set in cookie');
      } catch (error) {
        console.error('Failed to set token in cookie:', error);
        setError("Problem z ustawieniem sesji. Spróbuj ponownie.");
        return;
      }

      if (userCredential.user.emailVerified) {
        console.log("Email zweryfikowany, przekierowanie do strony głównej");
        navigate('/');
      } else {
        console.log("Email niezweryfikowany, wyświetlanie komunikatu");
        setError("Proszę zweryfikować adres e-mail. Sprawdź swoją skrzynkę pocztową.");
      }
    } catch (errorInstance) {
      if (errorInstance instanceof FirebaseError) {
        console.error('Firebase error:', errorInstance.code, errorInstance.message);
        setError(getErrorMessage(errorInstance.code));
      } else {
        console.error('Nieznany błąd podczas logowania:', errorInstance);
        setError("Logowanie nieudane. Spróbuj ponownie.");
      }
    }
  };

  return (
    <form onSubmit={handleLogin}>
      <div className="field">
        <label htmlFor="" className="label">Email</label>
        <div className="control has-icons-left">
          <input
            type="email"
            placeholder="np. bobsmith@gmail.com"
            className="input"
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="email"
            value={email}
            required
          />
          <span className="icon is-small is-left">
            <i className="fa fa-envelope"></i>
          </span>
        </div>
      </div>
      <div className="field">
        <label htmlFor="" className="label">Hasło</label>
        <div className="control has-icons-left">
          <input
            type="password"
            placeholder="******"
            className="input"
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="current-password"
            value={password}
            required
          />
          <span className="icon is-small is-left">
            <i className="fa fa-lock"></i>
          </span>
        </div>
      </div>
      {error && <div className="message is-danger m-4">
        <div className="message-body">
          {error}
        </div>
      </div>}
      <div className="field">
        <button className="button is-success" type="submit">Zaloguj się</button>
      </div>
      <div className="columns is-size-7 pt-3">
        <div className="column"><Link to="/password-reset">Nie pamiętasz hasła?</Link></div>
        <div className="column has-text-right">Pierwszy raz? <Link to="/register">Załóż konto</Link></div>
      </div>
    </form>
  );
};
