import { t } from "i18next";
import { useAuth } from '../../context/AuthContext';
import "./PasswordResetPage.scss";
import { Logo } from "../../components/Header/Logo";
import { PasswordResetForm } from "../../components/PasswordResetForm";
import { Helmet } from "react-helmet";

export const PasswordResetPage = () => {
  const { currentUser, logout } = useAuth();

  return (
    <div className="passwordResetPage">
      <Helmet>
        <title>Reset hasła - kalkulator mebli kuchennych | GoEstima</title>
        <meta name="description" content="Ustawianie nowego hasła do aplikacji | kalkulator mebli kuchennych i nie tylko | GoEstima" />
        <link rel="canonical" href="https://goestima.pl/password-reset" />
      </Helmet>
      <section className="passwordResetPage__content">
        <div className="box">
          <div className="has-text-centered pt-5">
            <Logo />
          </div>
          {!currentUser && (
            <>
              <div className="pb-5 column has-text-centered passwordResetPage__welcome">
                <p className="title is-4">Resetowanie hasła</p>
                <p className="subtitle is-6">Podaj adres e-mail Twojego konta, wyślemy Ci link do zresetowania hasła.</p>
              </div>
              <div className="column">
                <PasswordResetForm />
              </div>
            </>
          )}

          {currentUser && (
            <>
              <div className="has-text-centered column">Jesteś zalogowany.</div>
              <div className="has-text-centered column">
                <button title="Wyloguj się" onClick={logout} className="button is-primary">
                  Wyloguj się
                </button>
              </div>
            </>
          )}
        </div>
        <div className="has-text-centered content is-small">
          <span className="icon">
            <i className="fa-regular fa-copyright"></i>
          </span>
          2024 - {new Date().getFullYear()} Janiewicz&apos;s
          <br />
          Krzysztof Janiewicz
          <br />
          {t('footer-all-rights-reserved')}
        </div>
      </section>
    </div>
  );
};
