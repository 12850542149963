// src/config/categories.ts

export interface Category {
  name: string;
  catChild: string;
  icon?: string;
}

export const categories: Category[] = [
  { name: "Korpusy", catChild: "korpusy", icon: "fa-solid fa-box" },
  { name: "Blaty", catChild: "blaty", icon: "fa-solid fa-table" },
  { name: "Podnośniki", catChild: "podnosniki", icon: "fa-solid fa-arrow-up" },
  { name: "Zawiasy", catChild: "zawiasy", icon: "fa-solid fa-link" },
  { name: "Uchwyty", catChild: "uchwyty", icon: "fa-solid fa-hand-holding" },
  { name: "Szuflady", catChild: "szuflady", icon: "fa-solid fa-drawer" },
  { name: "Kosze cargo", catChild: "kosze-cargo", icon: "fa-solid fa-shopping-basket" },
  { name: "Szkło i lustra", catChild: "szklo-i-lustra", icon: "fa-solid fa-glass" },
  { name: "Oświetlenie i elektryka", catChild: "oswietlenie", icon: "fa-solid fa-lightbulb" },
  { name: "Fronty", catChild: "fronty", icon: "fa-solid fa-door-closed" },
  { name: "Szafy", catChild: "szafy", icon: "fa-solid fa-door-open" },
  { name: "Akcesoria", catChild: "akcesoria", icon: "fa-solid fa-plus" },
  { name: "Dodatki 1", catChild: "dodatkowe-1", icon: "fa-solid fa-plus" },
  { name: "Dodatki 2", catChild: "dodatkowe-2", icon: "fa-solid fa-plus" },
  { name: "Usługi", catChild: "uslugi", icon: "fa-solid fa-plus" },
];

export const getCategoryName = (catChild: string): string => {
  const category = categories.find(cat => cat.catChild === catChild);
  return category ? category.name : catChild;
};

export const getCategoryTranslations = (): { [key: string]: string } => {
  return categories.reduce((acc, category) => {
    acc[category.catChild] = category.name;
    return acc;
  }, {} as { [key: string]: string });
};
