import { useTranslation } from 'react-i18next';
import cn from 'classnames';

export const LanguageSelect: React.FC = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <div className="buttons">
      <button
        className={
          cn({
            'button': true,
            'is-small': true,
            'is-info': currentLanguage === 'pl',
          })
        }
        onClick={() => changeLanguage('pl')}
      >
        PL
      </button>
      <button
        className={
          cn({
            'button': true,
            'is-small': true,
            'is-info': currentLanguage === 'en',
          })
        }
        onClick={() => changeLanguage('en')}
      >
        EN
      </button>
      <button
        className={
          cn({
            'button': true,
            'is-small': true,
            'is-info': currentLanguage === 'de',
          })
        }
        onClick={() => changeLanguage('de')}
      >
        DE
      </button>
    </div>
  );
};
