import React from "react";
import { Outlet } from "react-router-dom";
import "./App.scss";
import { EditModeProvider } from './context/EditModeContext';
import { AuthProvider } from "./context/AuthContext";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/fonts/fonts.css';
import { CookieConsent } from './components/CookieConsent'; // Upewnij się, że ścieżka jest poprawna

export const App: React.FC = () => {
  const handleAcceptAllCookies = () => {
    console.log('Wszystkie cookies zaakceptowane');
    // Tutaj możesz dodać logikę do włączenia wszystkich cookies
  };

  const handleAcceptEssentialCookies = () => {
    console.log('Zaakceptowano tylko niezbędne cookies');
    // Tutaj możesz dodać logikę do wyłączenia opcjonalnych cookies
  };

  return (
    <>
      <ToastContainer />
      <AuthProvider>
        <EditModeProvider>
          <Outlet />
        </EditModeProvider>
      </AuthProvider>
      <CookieConsent 
        onAcceptAll={handleAcceptAllCookies} 
        onAcceptEssential={handleAcceptEssentialCookies}
      />
    </>
  );
};
