import React from "react";
import { getAuth, signInWithPopup, GoogleAuthProvider, UserCredential } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import config from '../../config';

interface LoginWithGoogleProps {
  title: string;
}

export const LoginWithGoogle: React.FC<LoginWithGoogleProps> = ({ title }) => {
  const navigate = useNavigate();
  const auth = getAuth();
  const apiUrl = config.API_URL;

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result: UserCredential) => {
        const user = result.user;
  
        // Sprawdź i ewentualnie dodaj użytkownika do bazy danych MySQL
        try {
          const response = await axios.post(`${apiUrl}/account/check-and-create`, {
            email: user.email,
            firebase_uid: user.uid,
            first_name: user.displayName?.split(" ")[0] || "",
            last_name: user.displayName?.split(" ")[1] || ""
          });
          console.log('User check/create response:', response.data);
        } catch (dbError) {
          console.error('Błąd przy sprawdzaniu/dodawaniu użytkownika do bazy danych:', dbError);
          // Możesz zdecydować, czy chcesz kontynuować logowanie, czy przerwać proces
        }  
        // Pobierz token Firebase
        const token = await user.getIdToken();
  
        // Ustaw token w ciasteczku za pomocą zapytania do serwera
        try {
          await axios.post(`${apiUrl}/account/set-token`, { token });
          console.log('Token set in cookie');
        } catch (error) {
          console.error('Failed to set token in cookie:', error);
        }
  
        // Przekieruj użytkownika po pomyślnym logowaniu
        setTimeout(() => {
          navigate('/');
        }, 500);
      })
      .catch((error) => {
        console.error("Błąd logowania przez Google:", error);
      });
  };

  return (
    <div className="has-text-centered">
      <button onClick={signInWithGoogle} className="button">
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27.9851 14.2618C27.9851 13.1146 27.8899 12.2775 27.6837 11.4094H14.2788V16.5871H22.1472C21.9886 17.8738 21.132 19.8116 19.2283 21.1137L19.2016 21.287L23.44 24.4956L23.7336 24.5242C26.4304 22.0904 27.9851 18.5093 27.9851 14.2618Z" fill="#4285F4"></path>
          <path d="M14.279 27.904C18.1338 27.904 21.37 26.6637 23.7338 24.5245L19.2285 21.114C18.0228 21.9356 16.4047 22.5092 14.279 22.5092C10.5034 22.5092 7.29894 20.0754 6.15663 16.7114L5.9892 16.7253L1.58205 20.0583L1.52441 20.2149C3.87224 24.7725 8.69486 27.904 14.279 27.904Z" fill="#34A853"></path>
          <path d="M6.15656 16.7113C5.85516 15.8432 5.68072 14.913 5.68072 13.9519C5.68072 12.9907 5.85516 12.0606 6.14071 11.1925L6.13272 11.0076L1.67035 7.62109L1.52435 7.68896C0.556704 9.58024 0.00146484 11.7041 0.00146484 13.9519C0.00146484 16.1997 0.556704 18.3234 1.52435 20.2147L6.15656 16.7113Z" fill="#FBBC05"></path>
          <path d="M14.279 5.3947C16.9599 5.3947 18.7683 6.52635 19.7995 7.47204L23.8289 3.6275C21.3542 1.37969 18.1338 0 14.279 0C8.69485 0 3.87223 3.1314 1.52441 7.68899L6.14077 11.1925C7.29893 7.82856 10.5034 5.3947 14.279 5.3947Z" fill="#EB4335"></path>
        </svg>
        &nbsp; {title}
      </button>
    </div>
  );
};
