// src/pages/Article.tsx
import React from "react";
import { useParams, Navigate } from "react-router-dom";
import { Art01 } from "./art01";
import { Art02 } from "./art02";
// Importuj kolejne artykuły...

const articleComponents: Record<string, React.FC> = {
  "rewolucja-w-branzy-meblarskiej": Art01,
  "jak-zaplanowac-kuchnie-na-wymiar": Art02,
  // Dodaj kolejne artykuły...
};

export const Article: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const ArticleComponent = slug ? articleComponents[slug] : null;

  if (!ArticleComponent) {
    return <Navigate to="/articles" replace />;
  }

  return <ArticleComponent />;
};
