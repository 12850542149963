import React from "react";
import { useAuth } from '../../context/AuthContext';
import { User, sendEmailVerification } from "firebase/auth";

export const ResendVerificationEmailLink: React.FC = () => {
  const { currentUser } = useAuth();

  const handleResendEmail = async () => {
    if (currentUser && !currentUser.emailVerified) {
      currentUser as User; // Potwierdzenie typu
      try {
        await sendEmailVerification(currentUser);
        alert('Email weryfikacyjny został ponownie wysłany.');
      } catch (error) {
        console.error('Wystąpił błąd podczas wysyłania emaila weryfikacyjnego:', error);
        alert('Błąd podczas wysyłania emaila weryfikacyjnego. Spróbuj ponownie później.');
      }
    }
  };

  return (
    <div className="m-5 has-text-centered">
      <div>
        Jeżeli nie otrzymałeś od nas wiadomości e-mail, możesz spróbować jeszcze raz:
      </div>
      <div className="pt-3">
        {currentUser && !currentUser.emailVerified && (
          <button onClick={handleResendEmail} className="button is-link is-light">
            Wyślij ponownie email weryfikacyjny
          </button>
        )}
      </div>
    </div>
  );
};
