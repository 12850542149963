import { createRoot } from "react-dom/client";
import { Root } from "./Root";
import './i18n';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './firebaseConfig';
import './axiosConfig';
import './index.css';

if (process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.log = () => {};
}

createRoot(document.getElementById("root") as HTMLElement).render(<Root />);
