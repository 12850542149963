import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollToTop = (): void => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    // Sprawdź, czy ścieżka zaczyna się od /estimate-furniture lub /products
    if (pathname.startsWith('/estimate-furniture') || pathname.startsWith('/products')) {
      // Nie wykonuj scrollowania dla tych ścieżek
      return;
    }

    if (!hash) {
      // Jeśli nie ma fragmentu (hash), przewiń na górę
      const scrollToTop = () => {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
          window.requestAnimationFrame(scrollToTop);
          window.scrollTo(0, c - c / 8);
        }
      };
      scrollToTop();
    } else {
      // Jeśli jest fragment (hash), przewiń do odpowiedniego elementu
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    }
  }, [pathname, hash]);
};
