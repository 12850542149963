// src\components\UserMenu\UserMenu.tsx
import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from "../../context/AuthContext";
import './UserMenu.scss';
import { Link, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';

export const UserMenu: React.FC = () => {
  const { logout } = useAuth();
  const [isActive, setIsActive] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);  // Ref do kontenera dropdown
  const location = useLocation();  // Pobierz obecną lokalizację

  const handleLogout = () => {
    logout();
    toast.info('Wylogowałeś się!');
    setIsActive(false);  // Zwiń menu po wylogowaniu
  };

  const toggleDropdown = () => {
    setIsActive(!isActive);
  };

  const closeDropdown = () => {
    setIsActive(false);  // Funkcja do zwijania menu
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Sprawdź, czy kliknięcie było poza dropdownem
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsActive(false);  // Zwiń dropdown
      }
    };

    // Dodaj nasłuchiwacz kliknięć na poziomie dokumentu
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Usuń nasłuchiwacz, gdy komponent jest odmontowywany
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const isActiveLink = (path: string) => {
    return location.pathname === path ? 'dropdown-item is-active' : 'dropdown-item';
  };

  return (
    <div className="columns is-mobile">
      <div className="column has-text-right">
        <div ref={dropdownRef} className={`dropdown is-right ${isActive ? 'is-active' : ''}`}>
          <div className="dropdown-trigger">
            <button className="button" aria-haspopup="true" aria-controls="dropdown-menu" onClick={toggleDropdown}>
              <span className="icon is-small">
                <i className="fa-solid fa-bars"></i>
              </span>
              <span>Menu</span>
            </button>
          </div>
          <div className="dropdown-menu" id="dropdown-menu" role="menu">
            <div className="dropdown-content">
              <Link to="/account" className={isActiveLink('/account')} onClick={closeDropdown}>
                <span className="icon">
                  <i className="fa-solid fa-cog"></i>
                </span>
                {' '}
                Ustawienia
              </Link>
              <Link to="/help" className={isActiveLink('/account')} onClick={closeDropdown}>
                <span className="icon">
                  <i className="fa-regular fa-circle-question"></i>
                </span>
                {' '}
                Pomoc
              </Link>
              <hr className="dropdown-divider" />
              <a className={isActiveLink('/logout')} onClick={handleLogout}>
                <span className="icon">
                  <i className="fa-solid fa-power-off"></i>
                </span>
                Wyloguj się
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
