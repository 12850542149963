import React from "react";
import "./NotFound.scss";
import { Helmet } from "react-helmet";
import errorImage from '../../assets/images/404.png';

export const NotFound: React.FC = () => {
  return (
    <div className="notFound has-text-centered is-vcentered">
      <Helmet>
        <title>404 | Kalkulator mebli kuchennych GoEstima</title>
        <meta name="description" content="Nie znaleziono strony." />
      </Helmet>
      <img src={errorImage} alt="Not found" className="mb-0" />
      <h1 className="title is-1 mb-4">
        404: Zabłądziłeś w Cyfrowej Puszczy
      </h1>
      <div className="content is-medium">
        <p className="mb-4">
          Ups! Wygląda na to, że zboczyłeś ze ścieżki i znalazłeś się w gęstwinie nieistniejących stron.
        </p>
        <p className="mb-4">
          Nasi cyfrowi leśnicy próbują Cię zlokalizować, ale w międzyczasie możesz:
        </p>
        <p className="m-5">
          <span>Użyć magicznego kompasu, aby wrócić na <a href="/">polanę główną</a></span><br />
          <span>Nasłuchiwać echa działających linków</span><br />
          <span>Zostawić okruszki kodu, żeby zaznaczyć swoją drogę powrotną</span>
        </p>
        <p className="mb-4">
          Pamiętaj: nawet najbardziej doświadczeni surferzy sieci czasem gubią się w naszym leśnym labiryncie!
        </p>
        <p className="is-italic">
          PS: Jeśli spotkasz jakiegoś bugowego wilka, nie karm go. Pracujemy nad jego poskromieniem.
        </p>
      </div>
    </div>
  );
};
