// HomePage.tsx
import React from "react";
import "./PrivacyPolicy.scss";
import { Helmet } from "react-helmet";

export const PrivacyPolicy: React.FC = () => {
  return (
    <div className="privacy-policy">
      <Helmet>
        <title>Polityka prywatności - prosta i czytelna bez haczyków | GoEstima</title>
        <meta name="description" content="Polityka prywatności. Informacja o cookies i o tym, w jakim zakresie przetwarzamy dane osobowe. | Kalkulator mebli kuchennych i nie tylko | GoEstima" />
        <link rel="canonical" href="https://goestima.pl/privacy-policy" />
      </Helmet>
      <h1>POLITYKA PRYWATNOŚCI SERWISU GOESTIMA</h1>

      <section>
        <h2>1. Informacje ogólne</h2>
        <ul>
          <li>1.1. Niniejsza Polityka Prywatności określa zasady przetwarzania i ochrony danych osobowych Użytkowników serwisu GoEstima, dostępnego pod adresem www.goestima.pl.</li>
          <li>1.2. Administratorem danych osobowych Użytkowników jest Krzysztof Janiewicz, prowadzący działalność gospodarczą pod adresem: ul. Sobieskiego 65a/18, 67-300 Szprotawa, NIP 9241745373.</li>
          <li>1.3. Kontakt w sprawach związanych z ochroną danych osobowych jest możliwy pod adresem e-mail: privacy@goestima.pl.</li>
        </ul>
      </section>

      <section>
        <h2>2. Zakres i cel przetwarzania danych osobowych</h2>
        <ul>
          <li>2.1. Administrator przetwarza dane osobowe Użytkowników w celu:
            <ul>
              <li>a) świadczenia usług oferowanych przez Serwis,</li>
              <li>b) obsługi Konta Użytkownika,</li>
              <li>c) realizacji umów zawartych za pośrednictwem Serwisu,</li>
              <li>d) rozpatrywania reklamacji,</li>
              <li>e) kontaktu z Użytkownikami w sprawach związanych z korzystaniem z Serwisu,</li>
              <li>f) prowadzenia działań marketingowych (w przypadku wyrażenia zgody przez Użytkownika).</li>
            </ul>
          </li>
          <li>2.2. Administrator przetwarza następujące kategorie danych osobowych:
            <ul>
              <li>a) dane identyfikacyjne (imię, nazwisko, nazwa firmy),</li>
              <li>b) dane kontaktowe (adres e-mail, numer telefonu),</li>
              <li>c) dane adresowe,</li>
              <li>d) dane dotyczące korzystania z Serwisu (np. historia Wycen).</li>
            </ul>
          </li>
        </ul>
      </section>

      <section>
        <h2>3. Podstawa prawna przetwarzania danych</h2>
        <ul>
          <li>3.1. Dane osobowe Użytkowników są przetwarzane na podstawie:
            <ul>
              <li>a) zgody Użytkownika (art. 6 ust. 1 lit. a RODO),</li>
              <li>b) niezbędności do wykonania umowy lub podjęcia działań przed zawarciem umowy (art. 6 ust. 1 lit. b RODO),</li>
              <li>c) prawnie uzasadnionego interesu Administratora (art. 6 ust. 1 lit. f RODO).</li>
            </ul>
          </li>
        </ul>
      </section>

      <section>
        <h2>4. Okres przechowywania danych</h2>
        <ul>
          <li>4.1. Dane osobowe Użytkowników są przechowywane przez okres niezbędny do realizacji celów, dla których zostały zebrane, a po tym czasie przez okres wymagany przez przepisy prawa lub do momentu przedawnienia ewentualnych roszczeń.</li>
        </ul>
      </section>

      <section>
        <h2>5. Prawa Użytkowników</h2>
        <ul>
          <li>5.1. Użytkownikom przysługują następujące prawa związane z przetwarzaniem ich danych osobowych:
            <ul>
              <li>a) prawo dostępu do swoich danych,</li>
              <li>b) prawo do sprostowania (poprawiania) swoich danych,</li>
              <li>c) prawo do usunięcia danych,</li>
              <li>d) prawo do ograniczenia przetwarzania danych,</li>
              <li>e) prawo do przenoszenia danych,</li>
              <li>f) prawo do wniesienia sprzeciwu wobec przetwarzania danych,</li>
              <li>g) prawo do cofnięcia zgody na przetwarzanie danych osobowych (jeżeli przetwarzanie odbywa się na podstawie zgody).</li>
            </ul>
          </li>
          <li>5.2. Aby skorzystać z powyższych praw, należy skontaktować się z Administratorem za pośrednictwem adresu e-mail podanego w punkcie 1.3.</li>
        </ul>
      </section>

      <section>
        <h2>6. Odbiorcy danych</h2>
        <ul>
          <li>6.1. Dane osobowe Użytkowników mogą być przekazywane następującym kategoriom odbiorców:
            <ul>
              <li>a) podmioty świadczące usługi hostingowe,</li>
              <li>b) podmioty świadczące usługi analityczne i statystyczne,</li>
              <li>c) podmioty świadczące usługi marketingowe,</li>
              <li>d) organy państwowe, gdy wynika to z obowiązku prawnego.</li>
            </ul>
          </li>
        </ul>
      </section>

      <section>
        <h2>7. Przekazywanie danych poza EOG</h2>
        <ul>
          <li>7.1. Administrator może przekazywać dane osobowe Użytkowników poza Europejski Obszar Gospodarczy (EOG) w związku z korzystaniem z usług Google (w tym Firebase) oraz innych zewnętrznych dostawców usług. W takim przypadku transfer danych odbywa się na podstawie standardowych klauzul umownych lub innych zabezpieczeń przewidzianych przez RODO.</li>
        </ul>
      </section>

      <section>
        <h2>8. Bezpieczeństwo danych</h2>
        <ul>
          <li>8.1. Administrator stosuje odpowiednie środki techniczne i organizacyjne zapewniające bezpieczeństwo przetwarzanych danych osobowych.</li>
        </ul>
      </section>

      <section>
        <h2>9. Pliki cookies</h2>
        <ul>
          <li>9.1. Serwis wykorzystuje pliki cookies w celu zapewnienia prawidłowego funkcjonowania, personalizacji treści oraz analizy ruchu.</li>
          <li>9.2. Użytkownik może w każdej chwili zmienić ustawienia dotyczące plików cookies w swojej przeglądarce internetowej.</li>
          <li>9.3. Serwis korzysta z następujących rodzajów plików cookies:
            <ul>
              <li>a) niezbędne - wymagane do prawidłowego funkcjonowania Serwisu,</li>
              <li>b) funkcjonalne - umożliwiające zapamiętanie preferencji Użytkownika,</li>
              <li>c) analityczne - umożliwiające zbieranie informacji o sposobie korzystania z Serwisu,</li>
              <li>d) marketingowe - umożliwiające wyświetlanie spersonalizowanych reklam.</li>
            </ul>
          </li>
          <li>9.4. Serwis korzysta z usług zewnętrznych firm, takich jak Google, które mogą umieszczać własne pliki cookies. Użytkownik wyraża zgodę na korzystanie z tych plików cookies poprzez korzystanie z Serwisu.</li>
        </ul>
      </section>

      <section>
        <h2>10. Zmiany Polityki Prywatności</h2>
        <ul>
          <li>10.1. Administrator zastrzega sobie prawo do wprowadzania zmian w niniejszej Polityce Prywatności.</li>
          <li>10.2. O wszelkich zmianach Użytkownicy zostaną poinformowani poprzez publikację nowej wersji Polityki Prywatności na stronie Serwisu.</li>
          <li>10.3. Korzystanie z Serwisu po wprowadzeniu zmian w Polityce Prywatności oznacza ich akceptację.</li>
        </ul>
      </section>

      <section>
        <h2>11. Postanowienia końcowe</h2>
        <ul>
          <li>11.1. W sprawach nieuregulowanych niniejszą Polityką Prywatności zastosowanie mają odpowiednie przepisy prawa polskiego i europejskiego.</li>
          <li>11.2. Niniejsza Polityka Prywatności obowiązuje od dnia [DATA].</li>
        </ul>
      </section>
    </div>
  );
};
