import { useState } from 'react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useAuth } from '../context/AuthContext'; // Zaimportuj hook useAuth
import { v4 as uuidv4 } from 'uuid';

export function useImageUpload() {
  const [uploading, setUploading] = useState(false);
  const { currentUser } = useAuth(); // Użyj hooka useAuth, aby uzyskać bieżącego użytkownika

  const uploadImage = async (file: File) => {
    if (!currentUser) {
      throw new Error("No user logged in"); // Rzuć błąd, jeśli nie ma zalogowanego użytkownika
    }

    setUploading(true);
    const storage = getStorage();
    // Użyj uid zalogowanego użytkownika w ścieżce do pliku
    const storageRef = ref(storage, `products/${currentUser.uid}/${uuidv4()}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise<string>((resolve, reject) => {
      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress.toFixed(2) + '% done');
        },
        (error) => {
          setUploading(false);
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setUploading(false);
            resolve(downloadURL);
          });
        }
      );
    });
  };

  return {
    uploading,
    uploadImage,
  };
}
