// src/components/Help/HelpTopics/FAQ.tsx
import React from 'react';

export const FAQ: React.FC = () => (
  <>
    <h3>Czy mogę zapisać moją wycenę?</h3>
    <p>Tak, możesz zapisać swoją wycenę w chmurze po zalogowaniu się na konto użytkownika. Wyceny można później edytować, drukować i zarządzać nimi.</p>

    <h3>Jak mogę uzyskać pomoc techniczną?</h3>
    <p>W przypadku problemów technicznych, skontaktuj się z naszym zespołem wsparcia przez formularz kontaktowy lub email janiewiczk@gmail.com.</p>
  </>
);
