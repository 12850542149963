import React, { useEffect, useState } from 'react';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
} from '@floating-ui/react';
import { useEditMode } from '../../context/EditModeContext';

interface TooltipProps {
  content: string;
  iconClassName?: string;
}

export const Tooltip: React.FC<TooltipProps> = ({ content, iconClassName = "fa-regular fa-circle-question" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isTooltipsEnabled } = useEditMode();

  useEffect(() => {
    console.log('Tooltip component: isTooltipsEnabled changed to', isTooltipsEnabled);
  }, [isTooltipsEnabled]);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'top',
    middleware: [offset(10), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  if (!isTooltipsEnabled) {
    return null;
  }

  return (
    <>
      <span ref={refs.setReference} {...getReferenceProps()}>
        <span className="icon is-size-5">
          <i className={iconClassName}></i>
        </span>
      </span>
      {isOpen && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={{
              ...floatingStyles,
              background: 'white',
              color: 'black',
              padding: '8px 12px',
              borderRadius: '4px',
              fontSize: '14px',
              maxWidth: '200px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
              border: '1px solid #e0e0e0',
              zIndex: 1000,
              wordWrap: 'break-word',
            }}
            {...getFloatingProps()}
          >
            {content}
          </div>
        </FloatingPortal>
      )}
    </>
  );
};
