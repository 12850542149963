// src/utils/cdnHelper.ts

const useCdn = process.env.REACT_APP_USE_CDN === 'true';
const cdnUrl = process.env.REACT_APP_CDN_URL || 'https://cdn.goestima.pl';
const firebaseStorageUrl = 'https://firebasestorage.googleapis.com/v0/b/goestima-c4ee2.appspot.com/o/';

export const getConditionalUrl = (url: string): string => {
  if (!url) return url;

  if (useCdn && url.startsWith(firebaseStorageUrl)) {
    const path = url.replace(firebaseStorageUrl, '').split('?')[0];
    const decodedPath = decodeURIComponent(path);
    return `${cdnUrl}/${decodedPath}`;
  }

  return url;
};