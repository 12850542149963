import React, { createContext, useContext, useState, useEffect, ReactNode, useCallback } from 'react';
import axios from 'axios';
import config from '../config';
import { useAuth } from './AuthContext';

interface EditModeContextType {
  isEditModeEnabled: boolean;
  isTooltipsEnabled: boolean;
  toggleEditMode: () => void;
  setTooltipsEnabled: (enabled: boolean) => Promise<void>;
}

const EditModeContext = createContext<EditModeContextType>({
  isEditModeEnabled: false,
  isTooltipsEnabled: true,
  toggleEditMode: () => {
    console.warn('toggleEditMode was called before it was initialized');
  },
  setTooltipsEnabled: async () => {
    console.warn('setTooltipsEnabled was called before it was initialized');
  },
});

export const useEditMode = () => useContext(EditModeContext);

interface EditModeProviderProps {
  children: ReactNode;
}

export const EditModeProvider: React.FC<EditModeProviderProps> = ({ children }) => {
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);
  const [isTooltipsEnabled, setIsTooltipsEnabled] = useState(true);
  const { currentUser } = useAuth();

  const fetchUserPreferences = useCallback(async () => {
    if (currentUser?.uid) {
      try {
        const response = await axios.get(`${config.API_URL}/account/users/${currentUser.uid}`);
        console.log('User preferences:', response.data);
        setIsTooltipsEnabled(response.data.tooltips_enabled);
        console.log('isTooltipsEnabled set to:', response.data.tooltips_enabled);
      } catch (error) {
        console.error('Error fetching user preferences:', error);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    fetchUserPreferences();
  }, [fetchUserPreferences]);

  const toggleEditMode = () => setIsEditModeEnabled((prev) => !prev);

  const updateTooltipsEnabled = async (enabled: boolean) => {
    if (currentUser?.uid) {
      try {
        await axios.put(`${config.API_URL}/account/users/${currentUser.uid}`, { tooltips_enabled: enabled });
        setIsTooltipsEnabled(enabled);
        console.log('Tooltips state updated:', enabled);
      } catch (error) {
        console.error('Error updating user preferences:', error);
      }
    }
  };

  return (
    <EditModeContext.Provider value={{ 
      isEditModeEnabled, 
      isTooltipsEnabled, 
      toggleEditMode, 
      setTooltipsEnabled: updateTooltipsEnabled 
    }}>
      {children}
    </EditModeContext.Provider>
  );
};
