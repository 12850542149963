import React, { createContext, useContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, signOut, User } from "firebase/auth";

interface AuthContextType {
  currentUser: User | null;
  logout: () => void;
  emailVerified: boolean; // Dodajemy właściwość do kontekstu
  loading: boolean; // Nowa właściwość dla statusu ładowania
}

export const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  logout: async () => {
    console.log("Default logout called");
  },
  emailVerified: false,
  loading: true
});

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [emailVerified, setEmailVerified] = useState(false);
  const [loading, setLoading] = useState(true); // Inicjalizacja statusu ładowania jako true

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, user => {
      setCurrentUser(user);
      setEmailVerified(user ? user.emailVerified : false);
      setLoading(false); // Ustawienie ładowania na false po zakończeniu procesu
    });

    return unsubscribe; // Czyszczenie subskrypcji
  }, []);

  const logout = () => {
    const auth = getAuth();
    return signOut(auth);
  };

  // Dodajemy emailVerified do wartości dostarczanych przez AuthContext.Provider
  return (
    <AuthContext.Provider value={{ currentUser, logout, emailVerified, loading  }}>
      {children}
    </AuthContext.Provider>
  );
};
