// HomePage.tsx
import React from "react";
import "./TermsOfService.scss";
import { Helmet } from "react-helmet";

export const TermsOfService: React.FC = () => {
  return (
    <div className="terms-of-service">
      <Helmet>
        <title>Regulamin - prosty i czytelny bez haczyków | GoEstima</title>
        <meta name="description" content="Regulamin korzystania z serwisu. Prosty i czytelny, bez haczyków. | kalkulator mebli kuchennych i nie tylko | GoEstima" />
        <link rel="canonical" href="https://goestima.pl/terms-of-service" />
      </Helmet>
      <h1>REGULAMIN SERWISU GOESTIMA</h1>

      <section>
        <h2>1. Postanowienia ogólne</h2>
        <ul>
          <li>1.1. Niniejszy Regulamin określa warunki korzystania z serwisu internetowego GoEstima, dostępnego pod adresem www.goestima.pl, oraz zasady świadczenia usług drogą elektroniczną przez Krzysztofa Janiewicza, prowadzącego działalność gospodarczą pod adresem: ul. Sobieskiego 65a/18, 67-300 Szprotawa, NIP 9241745373, zwanego dalej Usługodawcą.</li>
          <li>1.2. Serwis GoEstima świadczy usługi w zakresie tworzenia i zarządzania wycenami mebli na warunkach określonych w niniejszym Regulaminie.</li>
          <li>1.3. Użytkownik zobowiązany jest do zapoznania się z niniejszym Regulaminem przed rozpoczęciem korzystania z Serwisu oraz do przestrzegania jego postanowień.</li>
        </ul>
      </section>

      <section>
        <h2>2. Definicje</h2>
        <p>Ilekroć w Regulaminie jest mowa o:</p>
        <ul>
          <li>2.1. Serwisie - rozumie się przez to serwis internetowy GoEstima dostępny pod adresem www.goestima.pl,</li>
          <li>2.2. Użytkowniku - rozumie się przez to osobę fizyczną, osobę prawną lub jednostkę organizacyjną nieposiadającą osobowości prawnej, korzystającą z usług Serwisu,</li>
          <li>2.3. Koncie - rozumie się przez to indywidualne konto Użytkownika w Serwisie,</li>
          <li>2.4. Wycenie - rozumie się przez to dokument zawierający szczegółową kalkulację kosztów produkcji mebli, tworzony za pomocą narzędzi dostępnych w Serwisie,</li>
          <li>2.5. Produkcie - rozumie się przez to element składowy Wyceny, reprezentujący konkretny mebel lub jego część,</li>
          <li>2.6. Cenniku - rozumie się przez to informację o aktualnych cenach usług świadczonych przez Serwis, publikowaną na stronie głównej Serwisu.</li>
        </ul>
      </section>

      <section>
        <h2>3. Rejestracja i korzystanie z Serwisu</h2>
        <ul>
          <li>3.1. Korzystanie z pełnej funkcjonalności Serwisu wymaga założenia Konta.</li>
          <li>3.2. W celu założenia Konta, Użytkownik zobowiązany jest do podania prawdziwych danych osobowych oraz danych firmy (jeśli dotyczy).</li>
          <li>3.3. Użytkownik ponosi pełną odpowiedzialność za prawdziwość i prawidłowość podanych danych.</li>
          <li>3.4. Po założeniu Konta, Użytkownik otrzymuje dostęp do panelu administracyjnego, za pomocą którego może zarządzać swoimi Wycenami i Produktami.</li>
          <li>3.5. Użytkownik zobowiązuje się do nieudostępniania swojego Konta osobom trzecim oraz do zachowania w tajemnicy hasła dostępu do Konta.</li>
        </ul>
      </section>

      <section>
        <h2>4. Zakres usług Serwisu</h2>
        <ul>
          <li>4.1. Serwis umożliwia Użytkownikowi:
            <ul>
              <li>a) tworzenie i zarządzanie Wycenami mebli,</li>
              <li>b) korzystanie z bazy Produktów Użytkownika,</li>
              <li>c) tworzenie własnych Produktów,</li>
              <li>d) generowanie raportów i zestawień dotyczących Wycen,</li>
              <li>e) eksportowanie Wycen do formatów PDF i Excel.</li>
            </ul>
          </li>
          <li>4.2. W wersji darmowej Serwis umożliwia tworzenie do 10 Wycen miesięcznie. Limity dla wersji płatnych będą określone w Cenniku.</li>
          <li>4.3. Usługodawca zastrzega sobie prawo do wprowadzania zmian w zakresie świadczonych usług, o czym poinformuje Użytkowników z odpowiednim wyprzedzeniem.</li>
        </ul>
      </section>

      <section>
        <h2>5. Prawa i obowiązki Użytkownika</h2>
        <ul>
          <li>5.1. Użytkownik zobowiązuje się do korzystania z Serwisu zgodnie z obowiązującymi przepisami prawa, postanowieniami niniejszego Regulaminu oraz zasadami współżycia społecznego i dobrymi obyczajami.</li>
          <li>5.2. Użytkownik ponosi pełną odpowiedzialność za treść i prawidłowość wprowadzanych do Serwisu danych, w tym za prawdziwość informacji zawartych w Wycenach i Produktach.</li>
          <li>5.3. Użytkownik ma prawo do:
            <ul>
              <li>a) dostępu do swoich danych osobowych i ich poprawiania,</li>
              <li>b) usunięcia swojego Konta z Serwisu,</li>
              <li>c) zgłaszania reklamacji dotyczących funkcjonowania Serwisu.</li>
            </ul>
          </li>
          <li>5.4. Zakazane jest dostarczanie przez Użytkownika treści o charakterze bezprawnym, obraźliwym, naruszającym dobra osobiste osób trzecich lub ogólnie przyjęte normy społeczne.</li>
        </ul>
      </section>

      <section>
        <h2>6. Prawa i obowiązki Usługodawcy</h2>
        <ul>
          <li>6.1. Usługodawca zobowiązuje się do świadczenia usług z należytą starannością oraz zgodnie z postanowieniami niniejszego Regulaminu.</li>
          <li>6.2. Usługodawca zastrzega sobie prawo do:
            <ul>
              <li>a) czasowego zawieszenia świadczenia usług w związku z przeprowadzaniem prac konserwacyjnych lub modernizacyjnych,</li>
              <li>b) zablokowania dostępu do Konta Użytkownika w przypadku naruszenia postanowień niniejszego Regulaminu,</li>
              <li>c) usunięcia Konta Użytkownika w przypadku rażącego lub powtarzającego się naruszania postanowień Regulaminu.</li>
            </ul>
          </li>
          <li>6.3. Usługodawca nie ponosi odpowiedzialności za:
            <ul>
              <li>a) szkody wynikłe z nieprawidłowego korzystania z Serwisu przez Użytkownika,</li>
              <li>b) utratę danych spowodowaną awarią sprzętu, systemu lub innymi okolicznościami niezależnymi od Usługodawcy,</li>
              <li>c) szkody wynikłe z podania nieprawdziwych lub niekompletnych informacji przez Użytkownika.</li>
            </ul>
          </li>
        </ul>
      </section>

      <section>
        <h2>7. Płatności i rozliczenia</h2>
        <ul>
          <li>7.1. Korzystanie z podstawowej wersji Serwisu jest bezpłatne w zakresie określonym w punkcie 4.2 niniejszego Regulaminu.</li>
          <li>7.2. Usługodawca zastrzega sobie prawo do wprowadzenia odpłatnych wersji Serwisu o rozszerzonej funkcjonalności.</li>
          <li>7.3. Informacje o cenach usług płatnych będą publikowane w Cenniku dostępnym na stronie głównej Serwisu.</li>
          <li>7.4. W przypadku wprowadzenia usług płatnych, szczegółowe warunki płatności i rozliczeń zostaną określone w odrębnym dokumencie i podane do wiadomości Użytkowników.</li>
        </ul>
      </section>

      <section>
        <h2>8. Ochrona danych osobowych</h2>
        <ul>
          <li>8.1. Administratorem danych osobowych Użytkowników jest Usługodawca.</li>
          <li>8.2. Dane osobowe Użytkowników są przetwarzane zgodnie z obowiązującymi przepisami prawa oraz Polityką Prywatności Serwisu.</li>
          <li>8.3. Szczegółowe informacje dotyczące przetwarzania danych osobowych zawarte są w Polityce Prywatności dostępnej na stronie Serwisu.</li>
        </ul>
      </section>

      <section>
        <h2>9. Reklamacje</h2>
        <ul>
          <li>9.1. Użytkownik ma prawo do składania reklamacji dotyczących funkcjonowania Serwisu.</li>
          <li>9.2. Reklamacje należy składać drogą elektroniczną na adres e-mail: janiewiczk@gmail.com lub pisemnie na adres siedziby Usługodawcy.</li>
          <li>9.3. Reklamacja powinna zawierać co najmniej: imię i nazwisko Użytkownika, adres e-mail, opis problemu oraz oczekiwany sposób rozwiązania.</li>
          <li>9.4. Usługodawca rozpatrzy reklamację w terminie 14 dni od daty jej otrzymania i poinformuje Użytkownika o sposobie jej rozpatrzenia na adres e-mail podany w reklamacji.</li>
        </ul>
      </section>

      <section>
        <h2>10. Własność intelektualna</h2>
        <ul>
          <li>10.1. Wszelkie prawa własności intelektualnej do Serwisu, w tym do jego nazwy, domeny internetowej, szaty graficznej, funkcjonalności oraz zawartości, należą do Usługodawcy.</li>
          <li>10.2. Korzystanie z Serwisu nie oznacza nabycia przez Użytkownika jakichkolwiek praw własności intelektualnej do Serwisu.</li>
          <li>10.3. Zabronione jest kopiowanie, modyfikowanie, rozpowszechnianie, transmitowanie lub wykorzystywanie w inny sposób jakichkolwiek treści i elementów Serwisu bez uprzedniej zgody Usługodawcy.</li>
        </ul>
      </section>

      <section>
        <h2>11. Zmiany Regulaminu</h2>
        <ul>
          <li>11.1. Usługodawca zastrzega sobie prawo do zmiany niniejszego Regulaminu.</li>
          <li>11.2. O wszelkich zmianach Regulaminu Użytkownicy zostaną poinformowani poprzez publikację nowej wersji Regulaminu na stronie Serwisu oraz za pośrednictwem wiadomości e-mail (w przypadku Użytkowników posiadających Konto).</li>
          <li>11.3. Zmiany Regulaminu wchodzą w życie po upływie 14 dni od daty ich publikacji.</li>
          <li>11.4. Korzystanie z Serwisu po wejściu w życie zmian Regulaminu oznacza ich akceptację.</li>
        </ul>
      </section>

      <section>
        <h2>12. Postanowienia końcowe</h2>
        <ul>
          <li>12.1. W sprawach nieuregulowanych niniejszym Regulaminem zastosowanie mają przepisy prawa polskiego.</li>
          <li>12.2. Wszelkie spory wynikłe z korzystania z Serwisu będą rozstrzygane w pierwszej kolejności na drodze negocjacji, a w przypadku braku porozumienia, przez sąd właściwy dla siedziby Usługodawcy.</li>
          <li>12.3. Nieważność jednego z postanowień Regulaminu nie wpływa na ważność pozostałych postanowień.</li>
          <li>12.4. Regulamin wchodzi w życie z dniem 28.07.2024 r.</li>
        </ul>
      </section>
    </div>
  );
};
