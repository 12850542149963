import React from 'react';
import DisplaySingleProductContainer from './DisplaySingleProductContainer';
import { CommonItemsProps } from '../../types/commonTypes';

interface FormProps extends CommonItemsProps {
  estimateId: number;
  onRemoveProduct: (productId: string) => void;
  onProductUpdate: () => void;
  isLocked: boolean;
  category: string;
}

const DisplaySingleProduct: React.FC<FormProps> = (props) => {
  return <DisplaySingleProductContainer {...props} />;
};

export default DisplaySingleProduct;
