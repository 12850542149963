import React, { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Link } from "react-router-dom";

export const PasswordResetForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState('');
  const [error, setError] = useState("");

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("Form submitted with email:", email);  // Sprawdź, czy to loguje
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        console.log("Email sent successfully");  // Log sukcesu
        setMessage('Link do resetowania hasła został wysłany na Twój adres e-mail.');
        setError('');
      })
      .catch((error) => {
        console.log("Error sending email:", error);  // Log błędu
        setError('Wystąpił błąd przy wysyłaniu e-maila do resetowania hasła. Sprawdź, czy adres e-mail jest poprawny.');
        setMessage('');
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="field">
        <label htmlFor="" className="label">Email</label>
        <div className="control has-icons-left">
          <input
            type="email"
            placeholder="np. bobsmith@gmail.com"
            className="input"
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="email"
            value={email}
            required
          />
          <span className="icon is-small is-left">
            <i className="fa fa-envelope"></i>
          </span>
        </div>
      </div>
      {message && <div className="message is-success m-4">
        <div className="message-body">
          {message && <p>{message}</p>}
        </div>
      </div>}
      {error && <div className="message is-danger m-4">
        <div className="message-body">
          {error && <p>{error}</p>}
        </div>
      </div>}
      <div className="field">
        <button className="button is-success" type="submit">Resetuj hasło</button>
      </div>
      <div className="columns is-size-7 pt-3">
        <div className="column">Masz konto? <Link to="/login">Zaloguj się</Link></div>
        <div className="column has-text-right">Pierwszy raz? <Link to="/register">Załóż konto</Link></div>
      </div>
    </form>
  );
};
