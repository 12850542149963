import React from 'react';

export const Tutoriale: React.FC = () => (
  <>
    <p>
      <a
        className="is-size-5 has-text-weight-bold"
        target="_blank"
        rel="noreferrer"
        href="https://www.youtube.com/watch?v=qbeHKnLOEc0"
      >
        #01 Podstawy | Tutorial
      </a>
      <br />
      <span className="has-text-grey">
        czas trwania: 3:42 | aplikacja v0.1.2
        <br />
        Krótki pokaz jak wygląda tworzenie wyceny.
      </span>
    </p>
  </>
);
