// src/components/Help/HelpTopics/index.ts
import React from 'react';
import { FunkcjeIZasadaDzialania } from './FunkcjeIZasadaDzialania';
import { FAQ } from './FAQ';
import { Tutoriale } from "./Tutoriale";

interface Topic {
  id: string;
  title: string;
  component: React.ComponentType;
}

export const topics: Topic[] = [
  {
    id: "faq",
    title: "Najczęściej zadawane pytania (FAQ)",
    component: FAQ
  },
  {
    id: "tutoriale",
    title: "Tutoriale Video",
    component: Tutoriale
  },
  {
    id: "funkcje-i-zasada-dzialania",
    title: "Funkcje i zasada działania",
    component: FunkcjeIZasadaDzialania
  },
  // Tutaj możesz dodawać kolejne tematy
];
