// src\components\Header\Logo.tsx
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/logo.png';
import { Link } from "react-router-dom";

export const Logo: React.FC = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const motto = () => {
    switch (currentLanguage) {
    case 'en':
      return 'estimates and contracts mgmt';
    case 'de':
      return 'kosten- und vertragsmanagement';
    default:
      return 'wyceny i obsługa umów';
    }
  };

  return (
    <div className="has-text-centered">
      <Link to="/">
        <img
          src={logo}
          className="header__logo-image"
        />
      </Link>
      <p className="header__logo-motto">
        {motto()}
      </p>
    </div>
  );
};
