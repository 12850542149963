import React from 'react';
import './EditMode.scss';
import { useEditMode } from '../../context/EditModeContext';

export const EditMode: React.FC = () => {
  const { isEditModeEnabled, toggleEditMode } = useEditMode();

  const handleChange = () => {
    toggleEditMode();
  };

  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={isEditModeEnabled}
        onChange={handleChange}
      />
      <span className="slider round"></span>
    </label>
  );
};
