// src\config.ts
interface Config {
  API_URL: string;
  ENV: string;
}

const config: Config = {
  API_URL: process.env.REACT_APP_API_URL || 'https://goestima.pl/api',
  ENV: process.env.NODE_ENV || 'development'
};

export default config;