export const errorMessages: Record<string, string> = {
  'auth/invalid-email': 'Podany adres e-mail jest nieprawidłowy.',
  'auth/user-disabled': 'To konto użytkownika zostało wyłączone.',
  'auth/user-not-found': 'Nie znaleziono użytkownika z tym adresem e-mail.',
  'auth/wrong-password': 'Nieprawidłowe hasło.',
  'auth/invalid-credential': 'E-mail lub hasło nie zgadzają się.',
  'auth/account-exists-with-different-credential': 'Istnieje już konto z innym sposobem uwierzytelnienia dla tego adresu e-mail.',
  'auth/auth-domain-config-required': 'Konfiguracja domeny uwierzytelniania jest wymagana.',
  'auth/operation-not-allowed': 'Operacja nie jest dozwolona.',
  'auth/too-many-requests': 'Zbyt wiele prób logowania. Proszę spróbować później.',
  'auth/email-already-in-use': 'Adres e-mail jest już używany przez inne konto. Spróbuj zarejestrować się przez Google.',
  'auth/weak-password': 'Podane hasło jest zbyt słabe. Wymagane jest hasło o minimalnej długości 6 znaków.',
  'auth/network-request-failed': 'Błąd sieci. Proszę sprawdzić połączenie internetowe.',
  'auth/requires-recent-login': 'Akcja wymaga ponownego zalogowania z powodu długiego czasu nieaktywności.',
  'auth/password-too-short': 'Hasło musi mieć co najmniej 8 znaków.',
  'auth/password-too-weak': 'Hasło musi zawierać co najmniej jedną cyfrę, jedną literę wielką i jeden znak specjalny.',
  'auth/password-upper-lower-case': 'Hasło musi zawierać zarówno litery wielkie, jak i małe.'
};
