import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './CookieConsent.scss';

interface CookieConsentProps {
  onAcceptAll: () => void;
  onAcceptEssential: () => void;
}

export const CookieConsent: React.FC<CookieConsentProps> = ({ onAcceptAll, onAcceptEssential }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    const consentStatus = localStorage.getItem('cookieConsentStatus');
    if (!consentStatus) {
      setIsVisible(true);
    }
  }, []);

  const handleAcceptAll = () => {
    localStorage.setItem('cookieConsentStatus', 'all');
    setIsVisible(false);
    onAcceptAll();
  };

  const handleAcceptEssential = () => {
    localStorage.setItem('cookieConsentStatus', 'essential');
    setIsVisible(false);
    onAcceptEssential();
  };

  if (!isVisible) return null;

  return ReactDOM.createPortal(
    <div className="cookie-consent">
      <div className="cookie-consent__container">
        <p className="cookie-consent__text">
          <span className="icon has-text-white">
            <i className="fa-solid fa-cookie-bite"></i>
          </span>
          <span>
            Używamy plików cookie do funkcjonowania strony oraz celów analitycznych i marketingowych.
            {' '}
            <a href="/privacy-policy" className="has-text-info has-text-weight-bold">Polityka Prywatności.</a>
          </span>        </p>
        <div className="cookie-consent__buttons">
          <button className="button has-text-weight-medium is-info" onClick={handleAcceptAll}>
            Akceptuję
          </button>
          <button className="button has-text-weight-medium" onClick={handleAcceptEssential}>
            Tylko niezbędne
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};
