import axios from 'axios';
import config from './config';
import { getAuthToken } from "./auth";

const api = axios.create({
  baseURL: config.API_URL,
  withCredentials: true,
});

api.interceptors.request.use(
  async (config) => {
    const token = await getAuthToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Zastąp globalny axios
Object.assign(axios, api);

export default axios;
