import { UserData } from '../types/UserData';
import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useAuth } from '../context/AuthContext';
import config from '../config';

export const useUserData = (): [UserData | null, () => void] => {
  const { currentUser } = useAuth();
  const [userInfo, setUserInfo] = useState(null);
  const apiUrl = config.API_URL;

  const fetchUserData = useCallback(() => {
    if (currentUser && currentUser.uid) {
      axios.get(`${apiUrl}/account/users/${currentUser.uid}`)
        .then(response => {
          setUserInfo(response.data);
        })
        .catch(error => {
          console.error("Error fetching user data", error);
          setUserInfo(null);
        });
    }
  }, [currentUser, apiUrl]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  return [userInfo, fetchUserData];
};
