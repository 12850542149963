// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"; // Import modułu auth
import { getStorage } from "firebase/storage"; // Upewnij się, że importujesz getStorage

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAwUoEYWgqVNdBl6nDK1GOxcrvD3oAKumw",
  authDomain: "goestima-c4ee2.firebaseapp.com",
  projectId: "goestima-c4ee2",
  storageBucket: "goestima-c4ee2.appspot.com",
  messagingSenderId: "1027515934831",
  appId: "1:1027515934831:web:eb2b81db441c050028459a"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Inicjalizuj instancję Firebase Auth
const auth = getAuth(app);
const storage = getStorage(app); // Inicjalizacja Firebase Storage

export { auth, storage };

export default app;
