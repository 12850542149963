import React, { useState } from "react";
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { errorMessages } from '../../services/firebaseErrors';
import { FirebaseError } from "firebase/app";
import { Link } from "react-router-dom";
import axios from 'axios';
import config from '../../config';

export const RegisterForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [verificationMessage, setVerificationMessage] = useState("");
  const apiUrl = config.API_URL;

  const getErrorMessage = (errorCode: string): string => {
    return errorMessages[errorCode] || "Nieznany błąd, spróbuj ponownie.";
  };

  const addUserToDatabase = async (userData: { email: string | null, firebase_uid: string }): Promise<void> => {
    const maxRetries = 3;
    let retries = 0;

    while (retries < maxRetries) {
      try {
        const response = await axios.post(`${apiUrl}/account`, userData);
        console.log('Nowy użytkownik zapisany pomyślnie:', response.data);
        return;
      } catch (error) {
        console.error(`Błąd przy zapisie nowego użytkownika (próba ${retries + 1}/${maxRetries}):`, error);
        retries++;
        if (retries === maxRetries) {
          throw error;
        }
        await new Promise(resolve => setTimeout(resolve, 1000)); // Czekaj 1 sekundę przed kolejną próbą
      }
    }
  };

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setVerificationMessage("");

    const auth = getAuth();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      console.log("Zarejestrowano użytkownika:", userCredential.user);

      try {
        await addUserToDatabase({
          email: userCredential.user.email,
          firebase_uid: userCredential.user.uid
        });

        // Po udanej rejestracji wysyłamy e-mail weryfikacyjny
        await sendEmailVerification(userCredential.user);
        console.log("E-mail weryfikacyjny wysłany.");
        setVerificationMessage("Sprawdź swoją skrzynkę e-mail, aby zweryfikować konto.");
      } catch (dbError) {
        console.error("Błąd przy dodawaniu użytkownika do bazy danych:", dbError);
        setError("Wystąpił problem podczas rejestracji. Jeśli problem będzie się powtarzał, spróbuj zalogować się ponownie.");
        
        // Opcjonalnie: możesz tu dodać kod do usunięcia użytkownika z Firebase, jeśli chcesz zachować pełną spójność
        // await userCredential.user.delete();
      }
    } catch (errorInstance) {
      if (errorInstance instanceof FirebaseError) {
        setError(getErrorMessage(errorInstance.code));
      } else {
        setError("Rejestracja nieudana. Spróbuj ponownie.");
      }
    }
  };

  return (
    <>
      <form onSubmit={handleRegister}>
        <div className="field">
          <label htmlFor="" className="label">Email</label>
          <div className="control has-icons-left">
            <input
              type="email"
              placeholder="np. bobsmith@gmail.com"
              className="input"
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
              value={email}
              required
            />
            <span className="icon is-small is-left">
              <i className="fa fa-envelope"></i>
            </span>
          </div>
        </div>
        <div className="field">
          <label htmlFor="" className="label">Hasło</label>
          <div className="control has-icons-left">
            <input
              type="password"
              placeholder="******"
              className="input"
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="new-password"
              value={password}
              required
            />
            <span className="icon is-small is-left">
              <i className="fa fa-lock"></i>
            </span>
          </div>
        </div>
        {error && <div className="message is-danger m-4">
          <div className="message-body">
            {error}
          </div>
        </div>}
        <div className="field">
          <button className="button is-info" type="submit">Zarejestruj się</button>
        </div>
        <div className="columns is-size-7 pt-3">
          <div className="column"></div>
          <div className="column has-text-right">Masz konto? <Link to="/login">Zaloguj się</Link></div>
        </div>
      </form>
      {verificationMessage && <div>{verificationMessage}</div>}
    </>
  );
};
