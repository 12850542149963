// src\components\EstimateProducts\SortDropdown.tsx

import React from 'react';

type SortKey = 'name' | 'id' | 'sellingPrice';
type SortOrder = 'asc' | 'desc';

interface SortDropdownProps {
  sortKey: SortKey;
  sortOrder: SortOrder;
  onSortChange: (key: SortKey, order: SortOrder) => void;
  dropdownActive: boolean;
  setDropdownActive: (active: boolean) => void;
}

const SortDropdown: React.FC<SortDropdownProps> = ({ sortKey, sortOrder, onSortChange, dropdownActive, setDropdownActive }) => (
  <div className={`dropdown is-right ${dropdownActive ? 'is-active' : ''}`}>
    <div className="dropdown-trigger">
      <button className="button" aria-haspopup="true" aria-controls="dropdown-menu" onClick={() => setDropdownActive(!dropdownActive)}>
        <span className="icon">
          <i className="fa-solid fa-sort"></i>
        </span>
        <span>Sortowanie</span>
        <span className="icon is-small">
          <i className="fa fa-angle-down" aria-hidden="true"></i>
        </span>
      </button>
    </div>
    <div className="dropdown-menu" id="dropdown-menu" role="menu">
      <div className="dropdown-content">
        <a href="#" className={`dropdown-item ${sortKey === 'name' && sortOrder === 'asc' ? 'is-active' : ''}`} onClick={() => onSortChange('name', 'asc')}>
          Od A do Z
        </a>
        <a href="#" className={`dropdown-item ${sortKey === 'name' && sortOrder === 'desc' ? 'is-active' : ''}`} onClick={() => onSortChange('name', 'desc')}>
          Od Z do A
        </a>
        <hr className="dropdown-divider" />
        <a href="#" className={`dropdown-item ${sortKey === 'id' && sortOrder === 'desc' ? 'is-active' : ''}`} onClick={() => onSortChange('id', 'desc')}>
          Najnowsze
        </a>
        <a href="#" className={`dropdown-item ${sortKey === 'id' && sortOrder === 'asc' ? 'is-active' : ''}`} onClick={() => onSortChange('id', 'asc')}>
          Najstarsze
        </a>
        <hr className="dropdown-divider" />
        <a href="#" className={`dropdown-item ${sortKey === 'sellingPrice' && sortOrder === 'asc' ? 'is-active' : ''}`} onClick={() => onSortChange('sellingPrice', 'asc')}>
          Najtańsze
        </a>
        <a href="#" className={`dropdown-item ${sortKey === 'sellingPrice' && sortOrder === 'desc' ? 'is-active' : ''}`} onClick={() => onSortChange('sellingPrice', 'desc')}>
          Najdroższe
        </a>
      </div>
    </div>
  </div>
);

export default SortDropdown;
