import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import config from '../../config';
import './CreateEstimate.scss';
import { Helmet } from "react-helmet";

export const CreateEstimate: React.FC = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentUser?.uid) {
      alert('Musisz być zalogowany, aby utworzyć wycenę.');
      return;
    }

    try {
      const response = await axios.post(`${config.API_URL}/estimates`, {
        name,
        description,
        user_id: currentUser.uid
      });
      const newEstimateId = response.data.estimateId;
      navigate(`/estimate-furniture/${newEstimateId}`);
    } catch (error) {
      console.error('Error creating estimate:', error);
      alert('Nie udało się utworzyć wyceny. Spróbuj ponownie później.');
    }
  };

  return (
    <div className="createEstimate">
      <Helmet>
        <title>{`Nowa wycena dla ${name} | GoEstima`}</title>
        <meta name="description" content="Tworzen nowej wyceny. | GoEstima" />
        <link rel="canonical" href="https://goestima.pl/create-estimate" />
      </Helmet>
      <div className="columns is-centered">
        <div className="column is-half m-5">
          <h1 className="title has-text-centered">
            <span className="icon has-text-primary m-3">
              <i className="fa-solid fa-folder-plus"></i>
            </span>
            {name ? (
              <>
                <span>Wycena dla </span>
                <span className="has-text-primary">
                  {name}
                </span>
              </>
            ) : (
              <span>
                Nowa wycena
              </span>
            )}
          </h1>
          <form onSubmit={handleSubmit}>
            <div className="field">
              <label className="label">
                Nazwa wyceny <span className="has-text-danger">*</span>
              </label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  maxLength={250}
                  placeholder="Jan Kowalski (pole wymagane)"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Opis wyceny</label>
              <div className="control">
                <textarea
                  className="textarea"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  maxLength={1024}
                  placeholder="Wycena mebli kuchennych"
                  rows={4}
                />
              </div>
            </div>
            <div className="field">
              <div className="control">
                <button
                  type="submit"
                  className="button is-primary is-fullwidth"
                  disabled={!name.trim()}
                >
                  Utwórz wycenę
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
