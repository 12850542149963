import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './EstimateFurniture.scss';
import config from '../../config';
import { useAuth } from '../../context/AuthContext';
import { EstimateList } from '../../components/EstimateList';
import { useNavigate } from 'react-router-dom';
import { EstimateSearch } from '../../components/EstimateSearch/EstimateSearch';
import { Helmet } from "react-helmet";

interface RawEstimate {
  id: number;
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
  isLocked: number; // Zmiana na number, ponieważ z bazy danych przychodzi 0 lub 1
}

interface Estimate {
  id: number;
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
  isLocked: boolean;
}

export const EstimateFurniture: React.FC = () => {
  const [estimates, setEstimates] = useState<Estimate[]>([]); const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('created_at');
  const [sortOrder, setSortOrder] = useState<'ASC' | 'DESC'>('DESC');
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const fetchEstimates = useCallback(() => {
    console.log('fetchEstimates called');
    if (currentUser?.uid) {
      console.log('Fetching estimates for user:', currentUser.uid);
      console.log('Search term:', searchTerm); // Dodaj ten log
      axios.get<{ estimates: RawEstimate[], totalPages: number }>(`${config.API_URL}/estimates/user/${currentUser.uid}`, {
        params: {
          page: currentPage,
          limit: 20,
          search: searchTerm, // Przekazujemy cały searchTerm bez modyfikacji
          sortBy,
          sortOrder
        }
      })
        .then(response => {
          console.log('Raw response from API:', response.data);

          if (response.data.estimates && Array.isArray(response.data.estimates)) {
            const updatedEstimates: Estimate[] = response.data.estimates.map((estimate: RawEstimate) => {
              const processedEstimate = {
                ...estimate,
                isLocked: Boolean(estimate.isLocked) // Zmiana tutaj
              };
              console.log(`Processed estimate ${processedEstimate.id}, isLocked:`, processedEstimate.isLocked, 'Raw isLocked:', estimate.isLocked);
              return processedEstimate;
            });

            console.log('Processed estimates:', updatedEstimates);
            setEstimates(updatedEstimates);
            setTotalPages(response.data.totalPages);
          } else {
            console.error('Invalid data structure received from API');
          }
        })
        .catch(error => {
          console.error('Error fetching estimates:', error);
          if (axios.isAxiosError(error)) {
            console.error('Axios error details:', error.response);
          }
        });
    } else {
      console.log('No current user, skipping fetch');
    }
  }, [currentUser, currentPage, searchTerm, sortBy, sortOrder]);

  useEffect(() => {
    fetchEstimates();
  }, [fetchEstimates]);

  const handleDeleteEstimate = (id: number) => {
    if (window.confirm('Czy na pewno chcesz usunąć tę wycenę?')) {
      axios.delete(`${config.API_URL}/estimates/${id}`)
        .then(() => {
          fetchEstimates();
          console.log('Estimate deleted successfully');
        })
        .catch(error => {
          console.error('Error deleting estimate:', error);
          alert('Nie udało się usunąć wyceny. Spróbuj ponownie później.');
        });
    }
  };

  const handleCloneEstimate = async (id: number) => {
    try {
      await axios.post(`${config.API_URL}/estimates/${id}/clone`);
      fetchEstimates();
    } catch (error) {
      console.error('Error cloning estimate:', error);
      alert('Nie udało się sklonować wyceny. Spróbuj ponownie później.');
    }
  };

  const handleSortChange = (newSortBy: string) => {
    if (newSortBy === sortBy) {
      setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
    } else {
      setSortBy(newSortBy);
      setSortOrder('ASC');
    }
    setCurrentPage(1);
  };

  const handleSearch = (term: string) => {
    setSearchTerm(term);
    setCurrentPage(1);
  };

  const handleToggleLock = async (id: number) => {
    console.log(`Attempting to toggle lock for estimate ${id}`);
    try {
      const response = await axios.put(`${config.API_URL}/estimates/${id}/toggle-lock`);
      console.log('Toggle lock response:', response.data);
      if (response.data.success) {
        setEstimates(prevEstimates => {
          const newEstimates = prevEstimates.map(estimate =>
            estimate.id === id ? { ...estimate, isLocked: response.data.isLocked } : estimate
          );
          console.log('Updated estimates after toggle:', newEstimates);
          return newEstimates;
        });
      }
    } catch (error) {
      console.error('Error toggling estimate lock:', error);
      alert('Nie udało się zmienić stanu blokady wyceny. Spróbuj ponownie później.');
    }
  };

  useEffect(() => {
    console.log('Component mounted or dependencies changed');
    fetchEstimates();
  }, [fetchEstimates]);

  return (
    <div className="main">
      <Helmet>
        <title>Lista wycen | GoEstima</title>
        <meta name="description" content="Kalkulator mebli na wymiar" />
        <link rel="canonical" href="https://goestima.pl/estimate-furniture" />
      </Helmet>
      <div className="column">
        <EstimateSearch searchTerm={searchTerm} onSearch={handleSearch} />
      </div>
      <EstimateList
        estimates={estimates}
        onEdit={(estimate) => navigate(`/estimate-furniture/${estimate.id}`)}
        onDelete={handleDeleteEstimate}
        onClone={handleCloneEstimate}
        onSort={handleSortChange}
        sortBy={sortBy}
        sortOrder={sortOrder}
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
        itemsPerPage={20} // Tutaj określasz ilość wycen na stronę
        onToggleLock={handleToggleLock}
      />
    </div>
  );
};
