import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import config from '../../config';
import { toast } from 'react-toastify';
import './EditProduct.scss';
import defaultImage from '../../assets/images/default_image.jpg';
import { useProductForm } from '../../hooks/useProductForm';
import { getAuth } from 'firebase/auth';
import { Helmet } from "react-helmet";
import { getConditionalUrl } from '../../utils/cdnHelper';
import { categories } from '../../config/categories';
import { Tooltip } from "../Tooltip";

interface Product {
  id: number;
  title: string;
  description: string;
  unit: string;
  imageSrc: string;
  sellingPrice: string;
  purchasePrice: string;
  originalSellingPrice?: string;
  originalPurchasePrice?: string;
  extra?: number;
  widthAndHeight?: number;
  firebase_uid: string;
  is_active?: boolean;
  catChild: string; // Dodano to pole
}

interface ProductOption {
  id?: number;
  option_name: string;
  selling_price: number | null;
  purchase_price: number | null;
  original_option_id?: number | null;
  firebase_uid?: string;
}

export const EditProduct: React.FC = () => {
  // Zmiana: Dodano 'category' do useParams
  const { id, category } = useParams<{ id: string, category: string }>();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [product, setProduct] = useState<Product | null>(null);
  const [options, setOptions] = useState<ProductOption[]>([]);
  const [customPurchasePrice, setCustomPurchasePrice] = useState<string>('');
  const [customSellingPrice, setCustomSellingPrice] = useState<string>('');
  const [imageUploading, setImageUploading] = useState(false);
  const isSuperUser = currentUser?.email === 'spojrzenie@gmail.com';

  const initialState = {
    id: undefined,
    unit: '',
    imageSrc: '',
    sellingPrice: '',
    purchasePrice: '',
    extra: false,
    widthAndHeight: false,
    catParent: 'meble',
    catChild: '',
    estimatesId: '',
    title: '',
    description: '',
  };

  const onSubmitCallback = (result: { success: boolean; data?: { message: string; productId: number } | undefined; error?: string | Error }) => {
    if (result.success && result.data) {
      toast.success('Produkt został zaktualizowany!');
    } else {
      toast.error('Nie udało się zaktualizować produktu.');
    }
  };

  const {
    formData,
    setFormData,
    handleChange,
    handleFileChange: originalHandleFileChange,
    isSubmitting: formSubmitting,
    uploading
  } = useProductForm(initialState, onSubmitCallback);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        console.log(`Fetching product with id: ${id}`);
        const response = await axios.get(`${config.API_URL}/products/${id}`);
        const fetchedProduct = response.data;
        console.log('Fetched product:', fetchedProduct);

        if (fetchedProduct.firebase_uid !== currentUser?.uid && fetchedProduct.firebase_uid !== 'master') {
          toast.error('You do not have permission to edit this product.');
          return;
        }

        setProduct({
          ...fetchedProduct,
          extra: Boolean(fetchedProduct.extra),
          widthAndHeight: Boolean(fetchedProduct.widthAndHeight),
        });

        setFormData({
          id: fetchedProduct.id,
          unit: fetchedProduct.unit,
          imageSrc: fetchedProduct.imageSrc,
          sellingPrice: fetchedProduct.sellingPrice,
          purchasePrice: fetchedProduct.purchasePrice,
          extra: Boolean(fetchedProduct.extra),
          widthAndHeight: Boolean(fetchedProduct.widthAndHeight),
          catParent: 'meble',
          catChild: fetchedProduct.catChild,
          estimatesId: fetchedProduct.estimatesId || '',
          title: fetchedProduct.title,
          description: fetchedProduct.description,
        });

        if (fetchedProduct.firebase_uid === 'master' && !isSuperUser) {
          try {
            console.log(`Fetching user product settings for product ${id}`);
            const userSettingsResponse = await axios.get(`${config.API_URL}/products/${currentUser?.uid}/${id}/settings`);
            console.log('User settings response:', userSettingsResponse.data);
            if (userSettingsResponse.data) {
              setCustomPurchasePrice(userSettingsResponse.data.custom_purchase_price?.replace('.', ',') || fetchedProduct.purchasePrice?.replace('.', ',') || '');
              setCustomSellingPrice(userSettingsResponse.data.custom_selling_price?.replace('.', ',') || fetchedProduct.sellingPrice?.replace('.', ',') || '');
            } else {
              setCustomPurchasePrice(fetchedProduct.purchasePrice?.replace('.', ',') || '');
              setCustomSellingPrice(fetchedProduct.sellingPrice?.replace('.', ',') || '');
            }
          } catch (error) {
            console.error('Failed to fetch user product settings:', error);
            setCustomPurchasePrice(fetchedProduct.purchasePrice?.replace('.', ',') || '');
            setCustomSellingPrice(fetchedProduct.sellingPrice?.replace('.', ',') || '');
          }
        } else {
          setCustomPurchasePrice(fetchedProduct.purchasePrice?.replace('.', ',') || '');
          setCustomSellingPrice(fetchedProduct.sellingPrice?.replace('.', ',') || '');
        }

        try {
          console.log(`Fetching options for product ${id}`);
          const optionsResponse = await axios.get(`${config.API_URL}/products/${id}/options/${currentUser?.uid}`);
          console.log('Options response:', optionsResponse.data);
          if (optionsResponse.data.length > 0) {
            const mappedOptions = optionsResponse.data.map((option: ProductOption) => ({
              ...option,
              id: option.id,
              original_option_id: option.original_option_id,
              firebase_uid: option.firebase_uid,
              selling_price: option.selling_price !== null ? option.selling_price.toString().replace('.', ',') : null,
              purchase_price: option.purchase_price !== null ? option.purchase_price.toString().replace('.', ',') : null,
            }));
            console.log('Mapped options:', mappedOptions);
            setOptions(mappedOptions);
          } else if (fetchedProduct.firebase_uid === 'master' && !isSuperUser) {
            console.log('Fetching master options');
            const masterOptionsResponse = await axios.get(`${config.API_URL}/products/${id}/options/master`);
            console.log('Master options response:', masterOptionsResponse.data);
            const mappedMasterOptions = masterOptionsResponse.data.map((option: ProductOption) => ({
              ...option,
              id: undefined,
              option_name: option.option_name,
              firebase_uid: 'master',
              selling_price: option.selling_price !== null ? option.selling_price.toString().replace('.', ',') : null,
              purchase_price: option.purchase_price !== null ? option.purchase_price.toString().replace('.', ',') : null,
            }));
            console.log('Mapped master options:', mappedMasterOptions);
            setOptions(mappedMasterOptions);
          }
        } catch (error) {
          console.error('Failed to fetch product options:', error);
        }
      } catch (error) {
        console.error('Failed to fetch product:', error);
      }
    };

    fetchProduct();
  }, [id, currentUser, isSuperUser, setFormData]);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > 1024 * 1024) {
        toast.error('Plik jest za duży. Maksymalny dozwolony rozmiar to 1 MB.');
        return;
      }
      setImageUploading(true);
      await originalHandleFileChange(event);
      setImageUploading(false);
    }
  };

  const handleSave = async () => {
    if (product) {
      try {
        const auth = getAuth();
        const token = await auth.currentUser?.getIdToken();

        const updatedProduct = {
          ...product,
          unit: formData.unit,
          purchasePrice: customPurchasePrice.replace(',', '.'),
          sellingPrice: customSellingPrice.replace(',', '.'),
          title: formData.title,
          description: formData.description,
          catChild: formData.catChild,
          extra: formData.extra,
          widthAndHeight: formData.widthAndHeight,
          imageSrc: formData.imageSrc
        };

        const formattedOptions = options.map(option => ({
          ...option,
          selling_price: option.selling_price ? option.selling_price.toString().replace(',', '.') : null,
          purchase_price: option.purchase_price ? option.purchase_price.toString().replace(',', '.') : null,
          original_option_id: option.original_option_id || option.id
        }));

        if (product.firebase_uid === 'master') {
          if (isSuperUser) {
            await axios.put(`${config.API_URL}/products/${id}`, updatedProduct, {
              headers: { Authorization: `Bearer ${token}` }
            });

            if (formattedOptions.length > 0) {
              await axios.put(`${config.API_URL}/products/${id}/options/${currentUser?.uid}`, { options: formattedOptions }, {
                headers: { Authorization: `Bearer ${token}` }
              });
            }
          } else {
            await axios.patch(`${config.API_URL}/products/${currentUser?.uid}/${id}/settings`, {
              custom_purchase_price: customPurchasePrice === '' ? null : customPurchasePrice.replace(',', '.'),
              custom_selling_price: customSellingPrice === '' ? null : customSellingPrice.replace(',', '.'),
            }, {
              headers: { Authorization: `Bearer ${token}` }
            });

            if (formattedOptions.length > 0) {
              await axios.put(`${config.API_URL}/products/${id}/options/${currentUser?.uid}`, { options: formattedOptions }, {
                headers: { Authorization: `Bearer ${token}` }
              });
            }
          }
        } else {
          await axios.put(`${config.API_URL}/products/${id}`, updatedProduct, {
            headers: { Authorization: `Bearer ${token}` }
          });

          if (formattedOptions.length > 0) {
            await axios.put(`${config.API_URL}/products/${id}/options/${currentUser?.uid}`, { options: formattedOptions }, {
              headers: { Authorization: `Bearer ${token}` }
            });
          }
        }

        onSubmitCallback({ success: true, data: { message: 'Produkt został zaktualizowany', productId: product.id } });
        navigate(`/products/${updatedProduct.catChild}`);
      } catch (error) {
        console.error('Failed to update product:', error);
        onSubmitCallback({ success: false, error: 'Nie udało się zaktualizować produktu.' });
      }
    }
  };

  const handleOptionChange = (index: number, field: keyof ProductOption, value: string) => {
    const updatedOptions = [...options];
    if (field === 'selling_price' || field === 'purchase_price') {
      if (value.includes('.')) {
        toast.error('Proszę używać przecinka przy podawaniu ceny, a nie kropki.');
        return;
      }
      const parts = value.split(',');
      if (parts.length > 2 || (parts[1] && parts[1].length > 2)) {
        toast.error('Proszę podać nie więcej niż dwie cyfry po przecinku.');
        return;
      }
      updatedOptions[index] = {
        ...updatedOptions[index],
        [field]: value,
      };
    } else {
      updatedOptions[index] = {
        ...updatedOptions[index],
        [field]: value,
      };
    }

    if (updatedOptions[index].original_option_id === undefined) {
      updatedOptions[index].original_option_id = options[index].id;
    }
    setOptions(updatedOptions);
  };

  const handleAddOption = () => {
    const newOption = { option_name: '', selling_price: null, purchase_price: null };
    setOptions([...options, newOption]);
  };

  const handleRemoveOption = async (index: number) => {
    const optionToRemove = options[index];
    if (!product) {
      console.error('Product is null');
      return;
    }

    if (product.firebase_uid === 'master' && !isSuperUser) {
      console.error('Unauthorized attempt to remove master product option');
      return;
    }

    try {
      if (optionToRemove.id) {
        await axios.delete(`${config.API_URL}/products/${product.id}/options/${optionToRemove.id}`, {
          data: { firebaseUid: currentUser?.uid, userEmail: currentUser?.email }
        });
      }
      const updatedOptions = options.filter((_, i) => i !== index);
      setOptions(updatedOptions);
    } catch (error) {
      console.error('Failed to delete product option:', error);
    }
  };

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>, setter: (value: string) => void) => {
    const value = e.target.value;
    if (value.includes('.')) {
      toast.error('Proszę używać przecinka przy podawaniu ceny, a nie kropki.');
      return;
    }
    const parts = value.split(',');
    if (parts.length > 2 || (parts[1] && parts[1].length > 2)) {
      toast.error('Proszę podać nie więcej niż dwie cyfry po przecinku.');
      return;
    }
    setter(value);
  };

  const handleRemoveCustomOption = async (optionId: number | undefined) => {
    if (optionId === undefined) {
      toast.error('Nie można usunąć tej opcji.');
      return;
    }
    try {
      await axios.delete(`${config.API_URL}/products/${id}/options/${currentUser?.uid}/${optionId}`);
      setOptions(options.filter(option => option.id !== optionId));
      toast.success('Opcja została usunięta.');
    } catch (error) {
      console.error('Błąd podczas usuwania opcji:', error);
      toast.error('Nie udało się usunąć opcji.');
    }
  };

  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <form onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
      <div className="content">
        <Helmet>
          <title>{`Edycja: ${formData.title} | GoEstima`}</title>
          <meta name="description" content="Edycja produktu. | GoEstima" />
          {/* Zmiana: Dodano category do URL */}
          <link rel="canonical" href={`https://goestima.pl/products/${category}/edit/${formData.id}`} />
        </Helmet>
        <h1 className="title has-text-centered">
          <span className="icon-text">
            <span className="icon has-text-info">
              <i className="fa-regular fa-pen-to-square"></i>
            </span>
            &nbsp;
            Edytuj produkt
            &nbsp;
            <span className="has-text-info">
              {formData.title}
            </span>
          </span>
        </h1>
        <p className="has-text-weight-medium has-text-centered">
          {product.firebase_uid === 'master' && !isSuperUser && (
            <p className="has-text-danger has-text-weight-bold">
              <span className="icon">
                <i className="fa-solid fa-circle-exclamation"></i>
              </span>
              To jest produkt systemowy, możesz zmieniać tylko ceny.
            </p>
          )}
        </p>
      </div>
      <div className="columns">
        <div className="column is-one-quarter is-narrow has-text-centered">
          <p className="has-text-white box title is-4 p-3 mt-3 has-text-centered has-background-info">
            Obrazek
            {' '}
            <Tooltip
              content="
                Najlepiej będą wyglądały obrazki w proporcji 2:1. Max. rozmiar obrazka to 1 MB.
                "
              iconClassName="fa-regular fa-circle-question"
            />
          </p>
          {imageUploading
            ? <p className="p-4">
              <i className="fas fa-spinner fa-spin"></i> &nbsp; Zapisywanie...
            </p>
            : formData.imageSrc
              ? <div className="card"><div className="card-image">
                <figure className="image is-2by1">
                  <img className="addProductForm__image" src={getConditionalUrl(formData.imageSrc)} alt={formData.title} />
                </figure>
              </div></div>
              : <div className="card"><div className="card-image">
                <figure className="image is-2by1">
                  <img className="addProductForm__image" src={defaultImage} alt="Default product image" />
                </figure>
              </div></div>}
          {product.firebase_uid !== 'master' || isSuperUser ? (
            <label className="file-label addProductForm__label-center mt-4">
              <input
                className="file-input"
                type="file"
                onChange={handleFileChange}
                accept="image/*" />
              <span className="file-cta">
                <span className="file-icon">
                  <i className="fas fa-upload"></i>
                </span>
                <span className="file-label">Zmień obrazek</span>
              </span>
            </label>
          ) : null}
        </div>
        <div className="column">
          <p className="has-text-white box title is-4 p-3 mt-3 has-text-centered has-background-info">
            Opisy
          </p>
          <div className="field p-1">
            <label className="label">Nazwa <span className="has-text-danger">*</span></label>
            <div className="control has-icons-left has-icons-right">
              <input
                required
                type="text"
                className="input"
                name="title"
                value={formData.title}
                onChange={handleChange}
                placeholder="Nazwa produktu, max. 50 znaków"
                disabled={product.firebase_uid === 'master' && !isSuperUser}
              />
              <span className="icon is-small is-left">
                <i className="fa-solid fa-pencil"></i>
              </span>
            </div>
          </div>
          <div className="field p-1">
            <div className="field">
              <label className="label">Opis</label>
              <div className="control has-icons-left has-icons-right">
                <input
                  type="text"
                  className="input"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  placeholder="Krótki opis produktu, max. 50 znaków"
                  disabled={product.firebase_uid === 'master' && !isSuperUser}
                />
                <span className="icon is-small is-left">
                  <i className="fa-solid fa-pencil"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="field p-1">
            <label className="label">Kategoria <span className="has-text-danger">*</span></label>
            <div className="control has-icons-left">
              <div className="select">
                <select
                  name="catChild"
                  value={formData.catChild}
                  onChange={handleChange}
                  disabled={product.firebase_uid === 'master' && !isSuperUser}
                >
                  {categories.map(category => (
                    <option key={category.catChild} value={category.catChild}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="icon is-small is-left">
                <i className="fa-solid fa-list"></i>
              </div>
            </div>
          </div>
          <div className="field p-1">
            <div className="control">
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="extra"
                  checked={formData.extra}
                  onChange={handleChange}
                  disabled={product.firebase_uid === 'master' && !isSuperUser}
                />
                {' '}
                To jest dodatek
                <Tooltip
                  content="
                Jeżeli zaznaczysz tę opcję to ten produkt w wycenach będzie wyświetlany w dolnej sekcji DODATKI w swojej kategorii.
                "
                  iconClassName="fa-regular fa-circle-question is-size-6"
                />
              </label>
            </div>
          </div>
        </div>
        <div className="column">
          <p className="has-text-white box title is-4 p-3 mt-3 has-text-centered has-background-info">
            Szczegóły
          </p>
          <div className="field p-1">
            <label className="label">Cena dotyczy <span className="has-text-danger">*</span></label>
            <div className="control has-icons-left">
              <div className="select">
                <select
                  name="unit"
                  value={formData.unit}
                  onChange={handleChange}
                  disabled={formData.widthAndHeight || (product.firebase_uid === 'master' && !isSuperUser)}                >
                  <option value="">Wybierz jednostkę miary</option>
                  <option value="cm">cm</option>
                  <option value="m2">m2</option>
                  <option value="szt">szt</option>
                  <option value="h">h</option>
                </select>
              </div>
              <div className="icon is-small is-left">
                <i className="fa-solid fa-ruler-combined"></i>
              </div>
            </div>
          </div>
          <div className="field p-1">
            <label className="label">Cena sprzedaży <span className="has-text-danger">*</span></label>
            <div className="control has-icons-left has-icons-right">
              <input
                type="text"
                className="input"
                maxLength={10}
                pattern="^\d+(,\d{1,2})?$"
                name="sellingPrice"
                value={customSellingPrice}
                onChange={(e) => handlePriceChange(e, setCustomSellingPrice)}
                placeholder="Wpisz cenę, np. 1576,11"
              />
              <span className="icon is-small is-left">
                <i className="fa-solid fa-coins"></i>
              </span>
              <span className="is-right addProductForm__right-icon">
                zł
                {formData.widthAndHeight ? '/ m2' : formData.unit && `/ ${formData.unit}`}
              </span>
            </div>
          </div>
          <div className="field p-1">
            <label className="label">Cena zakupu <span className="has-text-danger">*</span></label>
            <div className="control has-icons-left has-icons-right">
              <input
                type="text"
                className="input"
                maxLength={10}
                pattern="^\d+(,\d{1,2})?$"
                name="purchasePrice"
                value={customPurchasePrice}
                onChange={(e) => handlePriceChange(e, setCustomPurchasePrice)}
                placeholder="Wpisz cenę, np. 1576,11"
              />
              <span className="icon is-small is-left">
                <i className="fa-solid fa-barcode"></i>
              </span>
              <span className="is-right addProductForm__right-icon">
                zł
                {formData.widthAndHeight ? '/ m2' : formData.unit && `/ ${formData.unit}`}
              </span>
            </div>
          </div>
          <div className="field p-1">
            <div className="control">
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="widthAndHeight"
                  checked={formData.widthAndHeight}
                  onChange={handleChange}
                  disabled={product.firebase_uid === 'master' && !isSuperUser}
                />
                {' '}
                Automatyczne obliczanie powierzchni
                <Tooltip
                  content="
                W wycenie będzie można podać szerokość i wysokość. Po włączeniu tej opcji podaj ceny za m2.
                "
                  iconClassName="fa-regular fa-circle-question is-size-6"
                />
              </label>
            </div>
          </div>
          {(isSuperUser || product.firebase_uid !== 'master' || (product.firebase_uid === 'master' && options.length > 0)) && (
            <>
              <div className="field p-1 pt-2">
                <label className="label">Opcje produktu
                  <Tooltip
                    content="
                W wycenie będziesz mógł wybrać dodaną tutaj opcję cenową produktu.
                "
                    iconClassName="fa-regular fa-circle-question is-size-6"
                  />
                </label>
                {options.map((option, index) => (
                  <div key={option.id || index}>
                    <div className="columns is-vcentered m-1 pb-2">
                      <div className="column">
                        <div className="field">
                          <label className="label is-small">Nazwa opcji</label>
                          <div className="control has-icons-left has-icons-right">
                            <input
                              type="text"
                              placeholder="np. lakier premium"
                              className="input"
                              maxLength={255}
                              value={option.option_name}
                              onChange={(e) => handleOptionChange(index, 'option_name', e.target.value)}
                              disabled={product?.firebase_uid === 'master' && !isSuperUser}
                            />
                            <span className="icon is-small is-left">
                              <i className="fa-solid fa-pencil"></i>
                            </span>
                          </div>
                        </div>
                        <div className="field">
                          <label className="label is-small">Cena sprzedaży</label>
                          <div className="control has-icons-left has-icons-right">
                            <input
                              type="text"
                              className="input"
                              maxLength={10}
                              pattern="^\d+(,\d{1,2})?$"
                              placeholder="Wpisz cenę, np. 29,90"
                              value={option.selling_price !== null ? option.selling_price.toString() : ''}
                              onChange={(e) => handleOptionChange(index, 'selling_price', e.target.value)}
                            />
                            <span className="icon is-small is-left">
                              <i className="fa-solid fa-coins"></i>
                            </span>
                            <span className="is-right addProductForm__right-icon">
                              zł
                              {formData.widthAndHeight ? '/ m2' : formData.unit && `/ ${formData.unit}`}
                            </span>
                          </div>
                        </div>
                        <div className="field">
                          <label className="label is-small">Cena zakupu</label>
                          <div className="control has-icons-left has-icons-right">
                            <input
                              type="text"
                              className="input"
                              maxLength={10}
                              pattern="^\d+(,\d{1,2})?$"
                              placeholder="Wpisz cenę, np. 24,90"
                              value={option.purchase_price !== null ? option.purchase_price.toString() : ''}
                              onChange={(e) => handleOptionChange(index, 'purchase_price', e.target.value)}
                            />
                            <span className="icon is-small is-left">
                              <i className="fa-solid fa-barcode"></i>
                            </span>
                            <span className="is-right addProductForm__right-icon">
                              zł
                              {formData.widthAndHeight ? '/ m2' : formData.unit && `/ ${formData.unit}`}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="column has-text-centered is-narrow">
                        {(product?.firebase_uid !== 'master' || isSuperUser) && (
                          <button
                            type="button"
                            className="button is-danger"
                            onClick={() => handleRemoveOption(index)}
                          >
                            <span className="icon">
                              <i className="fas fa-trash-alt"></i>
                            </span>
                          </button>
                        )}
                        {product?.firebase_uid === 'master' && !isSuperUser && option.original_option_id === null && option.firebase_uid === currentUser?.uid && (
                          <button
                            type="button"
                            className="button is-danger"
                            onClick={() => handleRemoveCustomOption(option.id)}
                          >
                            <span className="icon">
                              <i className="fas fa-trash-alt"></i>
                            </span>
                            <span>Usuń niestandardową opcję</span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="content pl-1">
                <button
                  type="button"
                  className="button is-info"
                  onClick={handleAddOption}
                  disabled={product.firebase_uid === 'master' && !isSuperUser}
                >
                  Dodaj opcję
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="has-text-centered">
        <input type="hidden" name="imageSrc" value={formData.imageSrc} onChange={handleChange} />
        <button
          className="button is-link"
          type="submit"
          disabled={
            formSubmitting
            || uploading
            || !formData.unit
            || !customPurchasePrice
            || !customSellingPrice
            || !formData.title
            || !formData.catChild
            || (product?.firebase_uid === 'master' && !isSuperUser && (!customPurchasePrice || !customSellingPrice))
          }
        >
          <span className="icon is-small">
            <i className="fa-solid fa-add"></i>
          </span>
          <span>Zapisz produkt</span>
        </button>
      </div>
    </form>
  );
};
