import { t } from "i18next";
import { LoginWithGoogle } from "../../components/LoginWithGoogle";
import { LoginForm } from "../../components/LoginForm";
import { useAuth } from '../../context/AuthContext';
import "./LoginPage.scss";
import { Logo } from "../../components/Header/Logo";
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const LoginPage = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  
  useEffect(() => {
    if (currentUser) {
      const timer = setTimeout(() => {
        navigate('/');
      }, 1000); // Przekierowanie po 3 sekundach

      return () => clearTimeout(timer);
    }
  }, [currentUser, navigate]);

  return (
    <div className="loginPage">
      <Helmet>
        <title>Logowanie - kalkulator mebli kuchennych | GoEstima</title>
        <meta name="description" content="Logowanie do aplikacji | kalkulator mebli kuchennych i nie tylko | GoEstima" />
        <link rel="canonical" href="https://goestima.pl/login" />
      </Helmet>
      <section className="loginPage__content">
        <div className="box">
          <div className="has-text-centered pt-5">
            <Logo />
          </div>
          {!currentUser && (
            <>
              <div className="pb-5 column has-text-centered loginPage__welcome">
                <p className="title is-4">Witamy ponownie!</p>
                <p className="subtitle is-6">Zaloguj się, aby skorzystać z aplikacji</p>
              </div>
              <div className="column">
                <LoginWithGoogle title="Zaloguj się z Google" />
              </div>
              <div>
                <div className="divider"> lub </div>
              </div>
              <div className="column">
                <LoginForm />
              </div>
            </>
          )}

          {currentUser && (
            <div className="has-text-centered column">
              Jesteś zalogowany.
            </div>
          )}
        </div>
        <div className="has-text-centered content is-small">
          <span className="icon">
            <i className="fa-regular fa-copyright"></i>
          </span>
          2024 - {new Date().getFullYear()} Janiewicz&apos;s
          <br />
          Krzysztof Janiewicz
          <br />
          {t('footer-all-rights-reserved')}
        </div>
      </section>
    </div>
  );
};
