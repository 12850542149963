// src/auth.ts
import { getAuth } from "firebase/auth";

export const getAuthToken = async (): Promise<string | null> => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (user) {
    return await user.getIdToken();
  } else {
    return null;
  }
};
