// src/data/articleList.ts

export interface ArticleInfo {
  slug: string;
  title: string;
  description: string;
  imageUrl: string;
}

export const articleList: ArticleInfo[] = [
  {
    slug: "rewolucja-w-branzy-meblarskiej",
    title: "Rewolucja w branży meblarskiej: Kalkulator mebli kuchennych online",
    description: "Poznaj, jak kalkulatory online rewolucjonizują proces wyceny mebli kuchennych.",
    imageUrl: "/articles-images/kalkulator-mebli.jpg"
  },
  {
    slug: "jak-zaplanowac-kuchnie-na-wymiar",
    title: "Jak zaplanować i zrealizować kuchnię na wymiar: Krok po kroku",
    description: "Dowiedz się, jak krok po kroku zaplanować i zrealizować kuchnię na wymiar. Od analizy potrzeb, przez projekt, po wybór wykonawcy - kompleksowy przewodnik.",
    imageUrl: "/articles-images/planowanie-kuchni.jpg"
  },
  // Dodaj więcej artykułów w miarę ich tworzenia
];
