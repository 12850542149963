import React, { useState, useEffect, useCallback } from 'react';
import { Tooltip } from "../Tooltip";

interface EstimateSearchProps {
  searchTerm: string;
  onSearch: (searchTerm: string) => void;
}

export const EstimateSearch: React.FC<EstimateSearchProps> = ({ searchTerm, onSearch }) => {
  const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm);

  useEffect(() => {
    setLocalSearchTerm(searchTerm);
  }, [searchTerm]);

  const debouncedSearch = useCallback((term: string) => {
    const timer = setTimeout(() => {
      onSearch(term);  // Przekazujemy całą frazę bez przycinania
    }, 300);

    return () => clearTimeout(timer);
  }, [onSearch]);

  useEffect(() => {
    return debouncedSearch(localSearchTerm);
  }, [localSearchTerm, debouncedSearch]);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalSearchTerm(newValue);
  }, []);

  const clearSearchTerm = useCallback(() => {
    setLocalSearchTerm('');
    onSearch('');
  }, [onSearch]);

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      clearSearchTerm();
    }
  }, [clearSearchTerm]);

  return (
    <div className="main">
      <div className="columns is-centered">
        <div className="column is-full-mobile is-two-thirds-tablet is-half-desktop">
          <div className="field">
            <label htmlFor="search-input" className="label has-text-grey">
              Zacznij pisać
              {' '}
              <Tooltip
                content="Możesz wyszukiwać podając tylko początki słów, np. 'ja no' znajdzie Jan Nowak."
                iconClassName="fa-regular fa-circle-question"
              />
            </label>
            <div className="control has-icons-left has-icons-right">
              <input
                id="search-input"
                className="input is-medium search-input"
                type="text"
                placeholder="Szukaj wycen..."
                value={localSearchTerm}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                aria-label="Wyszukaj wyceny"
              />
              <span className="icon is-small is-left">
                <i className="fas fa-search"></i>
              </span>
              {localSearchTerm && (
                <span
                  className="icon is-small is-right clear-search"
                  onClick={clearSearchTerm}
                  style={{ pointerEvents: 'auto', cursor: 'pointer' }}
                >
                  <i className="fas fa-times"></i>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
