import React from "react";
import "./AddProduct.scss";
// import { UserInfo } from '../../components/UserInfo';
// import { useUserData } from '../../hooks/useUserData';
import { AddProductForm } from "../../components/AddProductForm";

export const AddProduct: React.FC = () => {
  // const [userInfo, fetchUserData] = useUserData();

  return (
    <AddProductForm />
  );
};
