// src\hooks\useProductForm.ts
import { useState } from 'react';
import axios from 'axios';
import { useImageUpload } from './useImageUpload';
import config from '../config';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify';

interface ProductFormData {
  id?: number;
  unit: string;
  imageSrc: string;
  sellingPrice: string | number;
  purchasePrice: string | number;
  extra: boolean;
  widthAndHeight: boolean;
  catParent: string;
  catChild: string;
  estimatesId: string | null;
  firebase_uid?: string;
  title?: string;
  description?: string;
}

interface ProductOption {
  option_name: string;
  selling_price: string;
  purchase_price: string;
}

interface SubmitResult<T> {
  success: boolean;
  data?: T;
  error?: string | Error;
}

interface UseProductFormReturn<T> {
  formData: ProductFormData;
  setFormData: React.Dispatch<React.SetStateAction<ProductFormData>>;
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>, additionalData?: { options: ProductOption[] }) => Promise<void>;
  isSubmitting: boolean;
  uploading: boolean;
  onSubmitCallback: (result: SubmitResult<T>) => void; // Dodaj tę linię
}

export function useProductForm<T>(initialState: ProductFormData, onSubmitCallback: (result: SubmitResult<T>) => void): UseProductFormReturn<T> {
  const { currentUser } = useAuth();
  const user = currentUser ? currentUser.uid : null;
  const userEmail = currentUser ? currentUser.email : '';

  const [formData, setFormData] = useState<ProductFormData>(initialState);
  const { uploadImage, uploading } = useImageUpload();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const target = event.target as HTMLInputElement;
    const name = target.name;
    const isCheckbox = target.type === 'checkbox';
    const value = isCheckbox ? target.checked : target.value;

    if (name === 'description' && typeof value === 'string' && value.length > 255) {
      toast.error('Opis nie może przekraczać 255 znaków.');
      return;
    }

    if (name === 'title' && typeof value === 'string' && value.length > 50) {
      toast.error('Nazwa nie może przekraczać 50 znaków.');
      return;
    }

    if ((name === 'sellingPrice' || name === 'purchasePrice') && typeof value === 'string' && value.includes('.')) {
      toast.error('Proszę używać przecinka przy podawaniu ceny, a nie kropki.');
      return;
    }

    if ((name === 'sellingPrice' || name === 'purchasePrice') && typeof value === 'string' && value.includes(',')) {
      const parts = value.split(',');
      if (parts[1].length > 2) {
        toast.error('Proszę podać nie więcej niż dwie cyfry po przecinku.');
        return;
      }
    }

    setFormData(prev => {
      const updatedFormData = { ...prev, [name]: value };

      if (name === 'widthAndHeight' && value === true) {
        updatedFormData.unit = 'szt';
      }

      return updatedFormData;
    });
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const imageUrl = await uploadImage(file);
      setFormData(prev => ({
        ...prev,
        imageSrc: imageUrl
      }));
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>, additionalData?: { options: ProductOption[] }): Promise<void> => {
    event.preventDefault();
    setSubmitting(true);
  
    const finalFirebaseUid = userEmail === "spojrzenie@gmail.com" ? "master" : user;

    const preparedData = {
      ...formData,
      firebase_uid: finalFirebaseUid,
      estimatesId: formData.estimatesId || null,
      sellingPrice: typeof formData.sellingPrice === 'string' ? parseFloat(formData.sellingPrice.replace(',', '.')) : formData.sellingPrice,
      purchasePrice: typeof formData.purchasePrice === 'string' ? parseFloat(formData.purchasePrice.replace(',', '.')) : formData.purchasePrice,
      extra: formData.extra ? 1 : 0,
      widthAndHeight: formData.widthAndHeight ? 1 : 0,
      imageSrc: formData.imageSrc,
      options: additionalData?.options.map(option => ({
        ...option,
        selling_price: option.selling_price !== '' ? parseFloat(option.selling_price.replace(',', '.')) : null,
        purchase_price: option.purchase_price !== '' ? parseFloat(option.purchase_price.replace(',', '.')) : null,
      })),
    };
  
    try {
      let response;
      if (formData.id) {
        response = await axios.put(`${config.API_URL}/products/${formData.id}`, preparedData);
      } else {
        response = await axios.post(`${config.API_URL}/products/${finalFirebaseUid}`, preparedData);
      }

      const result: SubmitResult<T> = { success: true, data: response.data as T };
      setSubmitting(false);
      onSubmitCallback(result); // Wywołujemy callback tutaj
    } catch (error) {
      console.error('Failed to submit product:', error);
      const errorMessage = getErrorMessage(error);
      const result: SubmitResult<T> = { success: false, error: errorMessage };
      setSubmitting(false);
      onSubmitCallback(result); // Wywołujemy callback również w przypadku błędu
    }
  };
  
  const getErrorMessage = (error: unknown): string => {
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.data && error.response.data.message) {
        return error.response.data.message;
      } else if (error.message) {
        return error.message;
      }
    }
    return 'An error occurred while adding the product';
  };

  return {
    formData,
    setFormData,
    handleChange,
    handleFileChange,
    handleSubmit,
    isSubmitting,
    uploading,
    onSubmitCallback // Dodaj tę linię
  };
}
